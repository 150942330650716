import { EditOutlined, HolderOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Modal, Select, message } from 'antd';
import { debounce, sortBy } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useFetcher } from 'react-router-dom';
import { formDataToNestedJson } from '../../../library/utilities/formdata.js';
import { CustomSpecificationForm, ValidationForm as Form } from '../../form';
import { GeneralFieldWrapper, InputWrapper } from '../../inputs';
import styles from './specifciations.module.scss';

const SPECTYPES = Object.freeze({
    INBUILT: 'inbuilt',
    CUSTOM: 'custom',
    HIDDEN: 'hidden',
})

function getSiblings(element) {
    const siblings = [];
    const parent = element.parentElement;
    const children = parent.children;

    for (let i = 0; i < children.length; i++) {
        const sibling = children[i];
        if (sibling !== element && sibling.classList.contains(element.classList[0])) {
            siblings.push(sibling);
        }
    }

    return siblings;
}
const HiddenSpecification = ({ spec, selected = false, onSpecificationChange }) => {
    return (
        <>
            <div className={styles.itemLabel}>
                <Checkbox defaultChecked={selected} onChange={e => {
                    onSpecificationChange(e.target.checked)
                }}>{spec.text}</Checkbox>
            </div>
            <div className={styles.itemValue}>
                {spec.value_text}
            </div>
        </>
    )
}
HiddenSpecification.propTypes = {
    spec: PropTypes.object.isRequired,
    selected: PropTypes.bool,
    onSpecificationChange: PropTypes.func.isRequired,
}

const InbuiltSpecification = ({ spec, onSpecificationChange }) => {
    return (
        <>
            <div className={styles.itemLabel}>
                <HolderOutlined style={{ 'color': '#8f8f8f', margin: '0 10px 0 0' }} />{spec.text}
            </div>
            <div className={styles.itemValue}>
                <Select options={spec.values} defaultValue={spec.valueId ?? null} fieldNames={{ label: 'text', value: 'id' }} bordered={false} style={{ width: '100%' }} placeholder="Select" onChange={(value) => onSpecificationChange(value)} allowClear />
            </div>
        </>
    )
}
InbuiltSpecification.propTypes = {
    spec: PropTypes.object.isRequired,
    onSpecificationChange: PropTypes.func.isRequired,
}

const CustomSpecification = ({ spec, onEdit }) => (
    <>
        <div className={styles.itemLabel}>
            <HolderOutlined style={{ 'color': '#8f8f8f', margin: '0 10px 0 0' }} /> {spec.name}
        </div>
        <div className={styles.itemValue}>
            {spec.value}
            <EditOutlined className={styles.editAction} onClick={onEdit} />
        </div>
    </>
)
CustomSpecification.propTypes = {
    spec: PropTypes.object.isRequired,
    onEdit: PropTypes.func.isRequired,
}

const CustomSpecificationModal = ({ selectedSpecification = null, isLoading = false, onClose, onDelete, onSave }) => {
    const [form] = Form.useForm()

    return (
        <Modal
            title={!selectedSpecification?.id ? "Add New Specification" : 'Update Specification'}
            open={true}
            width={600}
            confirmLoading={isLoading}
            onCancel={onClose}
            footer={[
                ...[!!selectedSpecification?.id && <Button key="delete" type="danger" onClick={() => onDelete(selectedSpecification.id)}>Delete</Button>],
                <Button key="submit" type="primary" onClick={() => form.submit()}>Save</Button>,
                <Button key="cancel" onClick={onClose}>Cancel</Button>,
            ]}
            destroyOnClose
        >
            <CustomSpecificationForm form={form} entityId={selectedSpecification?.id} initialValues={selectedSpecification} onFinish={onSave} preserve={false} />
        </Modal>
    )
}
CustomSpecificationModal.propTypes = {
    selectedSpecification: PropTypes.object,
    isLoading: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
}

const ProjectItemSpecifications = ({ children, print_position, max_print_area_size, inbuiltSpecifications, hiddenSpecifications = [], specificationValues, hiddenSpecificationValues, customSpecifications, readonly = false, onSpecificationsChange, onCustomSpecificationAdd, onCustomSpecificationUpdate, onCustomSpecificationDelete, onHiddenSpecificationUpdate, onOrderChange, onDetailsChange }) => {
    const [addSpecificationModalVisible, SetAddSpecificationModalVisible] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [selectedCustomSpecification, setSelectedCustomSpecification] = useState(null)
    const selectedSpecs = useRef({})
    const dragSourceIndex = useRef(null)
    const dragTargetIndex = useRef(null)
    const fetcher = useFetcher()

    useEffect(() => {
        const _mergedSpecs = mergedSpecs()
        selectedSpecs.current = inbuiltSpecifications.reduce((prev, { id }) => {
            const _selectedSpec = specificationValues.find(({ id: _id }) => _id === id)
            return {
                ...prev,
                [id]: {
                    valueId: _selectedSpec?.value?.id,
                    sort_order: _selectedSpec?.value?.sort_order ?? _mergedSpecs.findIndex(({ id: _id }) => _id === id)
                }
            }
        }, {})
    }, [inbuiltSpecifications, specificationValues])


    const mergedSpecs = useCallback(() => {
        return sortBy([
            ...inbuiltSpecifications.map((spec, index) => ({
                ...spec,
                valueId: specificationValues.find(({ id }) => id === spec.id)?.value?.id,
                selectedValue: specificationValues.find(({ id }) => id === spec.id)?.value,
                ['type']: SPECTYPES.INBUILT,
                ['sort_order']: specificationValues.find(({ id }) => id === spec.id)?.sort_order ?? index
            })),
            // ...hiddenSpecifications.map((spec, index) => ({
            //     ...spec,
            //     name: spec.text,
            //     value: spec.value_text,
            //     type: SPECTYPES.HIDDEN,
            //     sort_order: spec.sort_order ?? index
            // })),
            ...customSpecifications.map((spec, index) => ({ ...spec, ['type']: SPECTYPES.CUSTOM, ['sort_order']: spec.sort_order ?? index }))
        ], 'sort_order')
    }, [inbuiltSpecifications, /* hiddenSpecifications, */ specificationValues, customSpecifications])

    const onDragStart = (event, index) => {
        dragSourceIndex.current = index
        const elem = event.currentTarget //Source element
        setTimeout(() => elem.firstChild.style.visibility = 'hidden', 1)
    }

    const onDragEnter = (event, index) => {
        dragTargetIndex.current = index
        if (dragSourceIndex.current === index) {
            return true
        }
        const elem = event.currentTarget //Target element
        const insertBefore = dragTargetIndex.current < dragSourceIndex.current;
        const shiftAmount = insertBefore ? elem.offsetHeight : -elem.offsetHeight
        elem.style.transform = `translateY(${shiftAmount}px)`
        getSiblings(elem).forEach((a, i) => {
            let translateY = 0
            if (insertBefore) {
                translateY = (i >= dragTargetIndex.current && i < dragSourceIndex.current) ? shiftAmount : 0
            }
            else {
                translateY = (i < dragTargetIndex.current && i > dragSourceIndex.current) ? shiftAmount : 0
            }
            a.style.transform = `translateY(${translateY}px)`
        });
    }

    const onDragEnd = (event) => {
        if (onOrderChange) {
            const _mergedSpecs = mergedSpecs()
            const [sourceItem] = _mergedSpecs.splice(dragSourceIndex.current, 1)
            _mergedSpecs.splice(dragTargetIndex.current, 0, sourceItem)
            let _customSpecifications = []
            _mergedSpecs.forEach((spec, index) => {
                const specType = spec.type
                delete spec.type
                spec.sort_order = index
                switch (specType) {
                    case SPECTYPES.INBUILT: {
                        selectedSpecs.current[spec.id].sort_order = index
                        break
                    }

                    case SPECTYPES.CUSTOM: {
                        _customSpecifications.push(spec)
                        break
                    }
                    default:
                        throw new Error('Invalid Specification Type')
                }
            })

            onOrderChange(selectedSpecs.current, _customSpecifications)
        }
        const elem = event.currentTarget;
        elem.firstChild.style.visibility = 'visible';
        [elem, ...getSiblings(elem)].forEach(a => {
            a.style.transform = 'translateY(0px)'
        })
        dragSourceIndex.current = null
        dragTargetIndex.current = null
    }

    const handleSpecificationChange = (spec, sort_order, value) => {
        if (readonly) {
            return true
        }
        selectedSpecs.current[spec] = {
            valueId: value,
            sort_order
        }
        if (onSpecificationsChange) {
            onSpecificationsChange(selectedSpecs.current)
        }
    }

    const handleHiddenSpecificationChange = (spec, selected) => {
        if (readonly) {
            return true
        }
        if (onHiddenSpecificationUpdate) {
            onHiddenSpecificationUpdate(spec, selected)
        }
    }

    const handleAddCustomeSpecificationClose = () => {
        if (readonly) {
            return true
        }
        SetAddSpecificationModalVisible(false)
        setSelectedCustomSpecification(null)
    }

    const handleCustomSpecificationSubmit = async (data, specId, errorHandler) => {
        if (readonly) {
            return true
        }
        setIsLoading(true)
        try {
            specId ?
                await onCustomSpecificationUpdate(specId, { ...data }) :
                await onCustomSpecificationAdd({ ...data, ['sort_order']: mergedSpecs().length })
            setIsLoading(false);
            SetAddSpecificationModalVisible(false)

        }
        catch {
            (e) => {
                if (!errorHandler(e)) {
                    message.error('Unable to save specification')
                    message.error(e.response.data.message)
                }
            }
        }

    }

    const handleCustomSpecificationDelete = async (_specId) => {
        if (readonly) {
            return true
        }
        setIsLoading(true)
        try {
            await onCustomSpecificationDelete(_specId)
            SetAddSpecificationModalVisible(false)
            setIsLoading(false)
            setSelectedCustomSpecification(null)
        }
        catch {
            (e) => {
                console.log(e);
                message.error('Unable to delete specification')
            }
        }

    }
    // const submitDetails = (value) => {
    //     // fetcher.submit({
    //     //     ...value,
    //     //     action: 'updateItem'
    //     // }, {
    //     //     method: 'post',
    //     //     encType: 'application/json',
    //     // })
    // }

    const debouncedSubmitDetails = !!onDetailsChange && debounce(onDetailsChange, 800)

    if (readonly) {
        return (
            <div className={`${styles.wrapper} ${styles.readonly}`}>
                {mergedSpecs()?.map((spec) => {
                    switch (spec.type) {
                        case SPECTYPES.INBUILT: {
                            return (
                                <div className={styles.item} key={spec.id}>
                                    <div className={styles.itemLabel}>
                                        {spec.text}
                                    </div>
                                    <div className={styles.itemValue}>
                                        {spec.selectedValue?.text}
                                    </div>
                                </div>
                            )
                        }

                        case SPECTYPES.CUSTOM:
                            return (
                                <div className={styles.item} key={spec.id} >
                                    <div className={styles.itemLabel}>
                                        {spec.name}
                                    </div>
                                    <div className={styles.itemValue}>
                                        {spec.value}
                                    </div>
                                </div>
                            )

                        default:
                            return ''

                    }
                })}
                {hiddenSpecifications.filter(s => hiddenSpecificationValues?.some(a => (a.spec_id === s.id)))?.map(spec => (
                    <div className={styles.item} key={spec.id} >
                        <div className={styles.itemLabel}>
                            {spec.text}
                        </div>
                        <div className={styles.itemValue}>
                            {spec.value_text}
                        </div>
                    </div>
                ))}
                <div className={styles.specBottom}>
                    {!!print_position && <GeneralFieldWrapper label="Print Position">
                        <span className={styles.itemValue}>{print_position}</span>
                    </GeneralFieldWrapper>}
                    {!!max_print_area_size && <GeneralFieldWrapper label="Max Print Area Size">
                        <span className={styles.itemValue}>{max_print_area_size}</span>
                    </GeneralFieldWrapper>}
                </div>
                {children}
            </div >
        )
    }

    return (
        <div className={styles.wrapper}>
            {mergedSpecs()?.map((spec, index) => (
                <div className="specItemWrapper" key={spec.id} draggable onDragStart={e => onDragStart(e, index, spec)} onDragEnter={e => onDragEnter(e, index, spec)} onDragEnd={e => onDragEnd(e, index, spec)}>
                    <div className={styles.item}>
                        {spec.type === SPECTYPES.INBUILT ?
                            <InbuiltSpecification spec={spec} onSpecificationChange={val => handleSpecificationChange(spec.id, spec.sort_order, val)} /> :
                            <CustomSpecification spec={spec} onEdit={() => {
                                setSelectedCustomSpecification(spec.id)
                                SetAddSpecificationModalVisible(true)
                            }} />
                        }
                    </div>
                </div>
            ))}
            {hiddenSpecifications?.map(spec => (
                <div className="specItemWrapper" key={spec.id}>
                    <div className={`${styles.item} ${styles.itemHidden}`}>
                        <HiddenSpecification key={spec.id} spec={spec} selected={hiddenSpecificationValues?.some(a => (a.spec_id === spec.id))} onSpecificationChange={selected => handleHiddenSpecificationChange(spec.id, selected)} />
                    </div>
                </div>
            ))}
            <fetcher.Form onChange={(e) => {
                if (!onDetailsChange) {
                    return
                }
                const data = formDataToNestedJson(new FormData(e.currentTarget))
                debouncedSubmitDetails(data)
            }}>
                <div className={styles.specBottom}>
                    <InputWrapper inputKey='print_position' name='print_position' defaultValue={print_position} label="Print Position" />
                    <InputWrapper inputKey='max_print_area_size' name='max_print_area_size' defaultValue={max_print_area_size} label="Max Print Area Size" rules={[{ required: true, message: 'Please Enter Max Print Area Size!' }]} />
                </div>
            </fetcher.Form>


            <Button style={{ 'margin': '10px 1% 1rem auto', 'width': '49%' }} icon={<PlusOutlined />} onClick={() => SetAddSpecificationModalVisible(true)}>Add Specification</Button>
            {children}
            {!!addSpecificationModalVisible && (
                <CustomSpecificationModal
                    selectedSpecification={selectedCustomSpecification ? customSpecifications.find(a => a.id === selectedCustomSpecification) : null}
                    isLoading={isLoading}
                    onClose={handleAddCustomeSpecificationClose}
                    onDelete={handleCustomSpecificationDelete}
                    onSave={handleCustomSpecificationSubmit}
                />
            )}
        </div >
    )
}

ProjectItemSpecifications.propTypes = {
    children: PropTypes.node,
    inbuiltSpecifications: PropTypes.arrayOf(PropTypes.object),
    specificationValues: PropTypes.arrayOf(PropTypes.object),
    hiddenSpecificationValues: PropTypes.arrayOf(PropTypes.object),
    hiddenSpecifications: PropTypes.arrayOf(PropTypes.object),
    customSpecifications: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        name_default: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        value_default: PropTypes.string.isRequired,
        sort_order: PropTypes.number,
    })),
    max_print_area_size: PropTypes.string,
    print_position: PropTypes.string,
    onSpecificationsChange: PropTypes.func,
    onCustomSpecificationAdd: PropTypes.func,
    onCustomSpecificationUpdate: PropTypes.func,
    onCustomSpecificationDelete: PropTypes.func,
    onHiddenSpecificationUpdate: PropTypes.func,
    onOrderChange: PropTypes.func,
    onDetailsChange: PropTypes.func,
    readonly: PropTypes.bool
}

export default ProjectItemSpecifications