import { FilterOutlined, ReloadOutlined } from '@ant-design/icons';
import {
    Button, Form, Input, Select
} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { convertDataTypes } from '../../../library/helpers/index.js';
import { ExecutiveEntitySelect } from '../../inputs';

const SepaPaymentFilterForm = ({ children, data, ...otherProps }) => {
    const [form] = Form.useForm()
    const { Option } = Select;

    const clearForm = () => {
        try {
            form.setFieldsValue(Object.fromEntries(
                Object.keys(form.getFieldsValue()).map(key => ([key, null]))
            ))
            form.submit()
        }
        catch (e) {
            console.log(e);
        }
    }

    return (
        <Form form={form} initialValues={convertDataTypes(data, {
            entity_id: parseInt,
            status: parseInt,
        })} layout='vertical'  {...otherProps}  >
            <Form.Item name='entity_id' label="Executive Entity">
                <ExecutiveEntitySelect allowClear getPopupContainer={triggerNode => triggerNode.parentElement}/>
            </Form.Item>
            <Form.Item name="paid_by" label="Paid By">
                <Input />
            </Form.Item>
            <Form.Item name="status" label="Status" >
                <Select getPopupContainer={triggerNode => triggerNode.parentElement}>
                    <Option value={1}>Paid</Option>
                    <Option value={null}>Unpaid</Option>
                </Select>
            </Form.Item>
            {children}
            <div className="actions">
                <Button htmlType="submit" type="primary" icon={<FilterOutlined />}>Apply</Button>
                <Button onClick={clearForm} icon={<ReloadOutlined />}>Reset</Button>
            </div>
        </Form>
    )
}

SepaPaymentFilterForm.propTypes = {
    children: PropTypes.node,
    data: PropTypes.object,
}

export default SepaPaymentFilterForm