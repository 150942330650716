
import React from 'react'
import { Page } from '../../../components/page'
import PropTypes from 'prop-types'
import { getSentCombiSuggest } from '../../../api/combi';
import { Table } from '../../../components/table';
import { useLoaderData, useNavigate, generatePath } from 'react-router-dom'
import { SentCombiColumns } from '../../../library/constants/tableColumns/SentCombiColuns';
import { searchParamsToObject } from '../../../library/helpers';
import { useFetch } from '../../../hooks';

const SentCombiDeals = (props) => {
    const initialData = useLoaderData()
    const navigate = useNavigate()
    //const [filtersVisible, setFiltersVisible] = useState(false)
    const [list, hasMore, isLoading, , {
        setSort,
        loadMore
    }] = useFetch({ initialData })
    const handleRowClick = (_, record) => {
        console.log(record);
        navigate(generatePath('/sent-combi-deals/:id', {
            id: record.id
        }))
    }
    return (
        <Page title={props.title} summary={initialData?.meta?.totalCount ? `(${initialData?.meta?.totalCount} Records)` : '(No Records)'} >
            <Table
                // className={styles.projectTable}
                columns={SentCombiColumns}
                data={list}
                /* perPage={Number(meta?.limit)} currentPage={Number(meta?.page)} total={Number(meta?.totalCount)} */
                isLoading={isLoading}
                onChange={setSort}
                onRowClick={handleRowClick}
                hasMore={hasMore}
                loadMore={loadMore}     
            />
        </Page>
    )
}

SentCombiDeals.Loader = async ({ request }) => {
    const url = new URL(request.url)
    const { filter, page, sort } = searchParamsToObject(url.searchParams)
    const { data, meta } = await getSentCombiSuggest({ filter, page, ...sort })
    //console.log({ Loading: data.map(r => r.id) })
    return { data, meta }
}

SentCombiDeals.propTypes = {
    title: PropTypes.string
}
export default SentCombiDeals