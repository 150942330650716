// Each file here is tied to the routes defined in the routes folder.
// All API calls originating from that page can be put here

/* eslint-disable no-undef */
import { generatePath } from 'react-router-dom';
import { axiosInstance } from '../common';

// Define all api endpoint urls at the top
const ENDPOINT_GENERAL = '/project';
const ENDPOINT_SINGLE = '/project/:id';
const ENDPOINT_GENERAL_ACTION = '/project/:action';
const ENDPOINT_SINGLE_ACTION = '/project/:id/:action';
const ENDPOINT_SINGLE_CUSTOM_ACTION = '/project/:projectId/:action/:actionId';
const ENDPOINT_SINGLE_CUSTOM_ACTION_SUBACTION = '/project/:projectId/:action/:actionId/:subAction';
const ENDPOINT_SEARCH = '/project/search-project';

export const getAllProjects = (payload) => {
    return axiosInstance.get(ENDPOINT_GENERAL, { params: payload })
}

export const getProjectKanabanCount = (payload) => {
    return axiosInstance.get(generatePath(ENDPOINT_GENERAL_ACTION, {
        action: 'kanban-count'
    }), { params: payload }).then(({ data }) => data)
}

export const createProject = (payload) => {
    return axiosInstance.post(ENDPOINT_GENERAL, payload)
}

export const deleteProject = (id) => {
    return axiosInstance.delete(generatePath(ENDPOINT_SINGLE, {
        id
    })).then(({ message }) => message)
}

export const getProjectDetails = (id) => {
    return axiosInstance.get(generatePath(ENDPOINT_SINGLE, {
        id
    })).then(({ data }) => data)
}

export const updateProjectDetails = (id, payload) => {
    return axiosInstance.patch(generatePath(ENDPOINT_SINGLE, {
        id
    }), payload)
}

export const copyItemToNewProject = (id, payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_ACTION, {
        id,
        action: 'copy-project'
    }), payload)

}

export const copyItemToNewExistingProject = (id, itemId) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_ACTION, {
        id,
        action: 'copy-item-open-project'
    }), ({
        ids: [itemId]
    })).then(({ message }) => message)

}


export const projectAssignOrderManager = (projectId, orderManagerId) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_CUSTOM_ACTION, {
        projectId,
        action: 'order-manager',
        actionId: orderManagerId
    }))
}

export const confirmVendor = (id, payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_ACTION, {
        id,
        action: 'select-vendor'
    }), payload)
}



export const changeStatus = (id, status_id, payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_CUSTOM_ACTION, {
        projectId: id,
        action: 'change-status',
        actionId: status_id
    }), payload)
}
export const sendProjectClientQuotation = (id, payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_ACTION, {
        id,
        action: 'client-quotation'
    }), Object.entries(payload).map(([item_id, vendor_id]) => ({
        item_id,
        vendor_id
    }))).then(({ message }) => message)
}

export const getClientQuotations = (projectId) => {
    return axiosInstance.get(generatePath(ENDPOINT_SINGLE_ACTION, {
        id: projectId,
        action: 'client-quotation'
    })).then(({ data }) => data)
}

export const cancelClientQuotation = (projectId) => {
    return axiosInstance.delete(generatePath(ENDPOINT_SINGLE_ACTION, {
        id: projectId,
        action: 'client-quotation'
    })).then(({ message }) => message)
}

export const resendClientQuotation = (projectId) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_ACTION, {
        id: projectId,
        action: 'resend-client-quotation'
    })).then(({ message }) => message)
}

export const searchProjects = (query) => {
    return axiosInstance.get(ENDPOINT_SEARCH, {
        params: {
            q: query
        }
    }).then(({ data }) => data)
}

export const uploadImages = (projectId, images) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_ACTION, {
        id: projectId,
        action: 'upload-document'
    }), {
        context: 'project_images',
        image_ids: images,
    }).then(({ message }) => message)
}

export const uploadVisual = (projectId, imageData) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_ACTION, {
        id: projectId,
        action: 'upload-document'
    }), {
        context: 'visual',
        image_ids: imageData,
    }).then(({ message }) => message)
}

export const deleteVisual = (projectId, url) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_ACTION, {
        id: projectId,
        action: 'delete-document'
    }), {
        context: 'visual',
        file: url,
    }).then(({ message }) => message)
}


export const deleteImage = (projectId, url, context) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_ACTION, {
        id: projectId,
        action: 'delete-document'
    }), {
        context,
        file: url,
    }).then(({ message }) => message)
}

export const getClientQuotationPreview = (projectId, selections) => {
    let previewUrl = generatePath(ENDPOINT_SINGLE_ACTION, {
        id: projectId,
        action: 'preview-client-quotation'
    })

    const url = new URL(`${process.env.REACT_APP_BASE_API_URL}/${process.env.REACT_APP_VERSION_V1}/${previewUrl}`)

    Object.entries(selections).forEach(([itemId, vendorId], index) => {
        url.searchParams.set(`item_vendor_ids[${index}][item_id]`, itemId)
        url.searchParams.set(`item_vendor_ids[${index}][vendor_id]`, vendorId)
    })
    return url.href
}

export const getProjectCancellationInvoice = (projectId, itemIds) => {
    return axiosInstance.get(generatePath(ENDPOINT_SINGLE_ACTION, {
        id: projectId,
        action: 'cancel'
    }), {
        params: {
            item_ids: itemIds
        }
    }).then(({ data }) => data)
}

export const createProjectCancellationInvoice = (projectId, itemIds, reason, invoice_lines, footer_text) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_ACTION, {
        id: projectId,
        action: 'cancel'
    }), {
        item_ids: itemIds,
        reason,
        invoice: {
            lines: invoice_lines,
            footer_text
        }
    }).then(({ message }) => message)
}

export const sendCancellationWaiveOffRequest = (projectId, itemIds, reason, comment) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_ACTION, {
        id: projectId,
        action: 'waive-request'
    }), {
        item_ids: itemIds,
        cancellation_reason: reason,
        request_comment: comment,
    }).then(({ message }) => message)
}

export const resumeProject = (projectId, itemIds, comment) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_ACTION, {
        id: projectId,
        action: 'items-resume-from-cancel'
    }), {
        item_ids: itemIds,
        comment,
    }).then(({ message }) => message)
}


export const approveCancellationWaiveOffRequest = (projectId, requestId, comment) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_CUSTOM_ACTION_SUBACTION, {
        projectId,
        action: 'waive-request',
        actionId: requestId,
        subAction: 'approved',
    }), {
        response_comment: comment,
    }).then(({ message }) => message)
}

export const declineCancellationWaiveOffRequest = (projectId, requestId, comment) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_CUSTOM_ACTION_SUBACTION, {
        projectId,
        action: 'waive-request',
        actionId: requestId,
        subAction: 'declined',
    }), {
        response_comment: comment,
    }).then(({ message }) => message)
}
export const getProjectActivityLogs = (projectId) => axiosInstance.get(generatePath(ENDPOINT_SINGLE_ACTION, {
    id: projectId,
    action: 'audit-trial'
})).then(({ data }) => data)
