import { UserAddOutlined } from '@ant-design/icons';
import {
	Button,
	Checkbox,
	Input,
	Select,
	Space
} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { ValidationForm as Form } from '../';
import { generateRouteUrl } from '../../../library/constants/routes.js';
import PaymentMethods from '../../../library/dynamics/payment-method';
import PaymentTerm from '../../../library/dynamics/payment-terms';
import { normalizeAlphaNumeric, normalizeEmail } from '../../../library/helpers/normalize';
import { CountrySelect, CurrencySelectDropDown, LanguageSelect, Percent, PhoneInput } from '../../inputs';
import styles from './vendor-details.module.scss';

const VendorDetails = ({ children, getValidationStatus, form, hasUsers, disabled, id, ...otherProps }) => {

	const options = [
		{
			label: 'Item',
			value: 1,
		},
		{
			label: 'Finisher',
			value: 2,
		},
		{
			label: 'Transport',
			value: 3,
		},
		{
			label: 'Duty Fees',
			value: 4,
		}
	];


	const validationRules = {
		company: [
			{
				required: true,
				message: 'Company is required'
			}
		],
		payment_term: [
			{
				required: true,
				message: 'Payment term is required'
			}
		],
		payment_method_id: [
			{
				required: true,
				message: 'Payment method is required'
			}
		],
		country_id: [
			{
				required: true,
				message: 'Country is required'
			}
		],
		language_id: [
			{
				required: true,
				message: 'Language is required'
			}
		],
		contact_country_code: [
			{
				required: true,
				message: 'Country code is required'
			}
		],
		contact_number: [
			{
				required: true,
				message: 'Contact number is required'
			}
		],
		email: [
			{
				required: true,
				message: 'Email is required'
			},
			{
				type: 'email',
				message: 'Email is invalid'
			}
		],
		currency_id: [
			{
				required: true,
				message: 'Currency is required'
			}
		],
		// entity_id: [
		// 	{
		// 		required: true
		// 	}
		// ],
	}
	return (
		<div>
			<Form form={form} name='vendorDetails' onValidationStatusChange={getValidationStatus ?? undefined} layout="vertical" validateTrigger={['onChange', 'onBlur']} className={styles.FormWrapper} validationRules={validationRules} disabled={disabled} {...otherProps}>
				<div className={styles.grid}>
					<div className={styles.row}>
						<Form.Item normalize={normalizeAlphaNumeric} name="company" label="Company" className={styles.halfWidth}>
							<Input />
						</Form.Item>
						<Form.Item label="Phone Number" className={styles.halfWidth} required>
							<PhoneInput valuePropName={{ code: 'phone_country_code', number: 'phone_number' }} dependencies={['country_id']} label='Phone Number' required />
						</Form.Item>
					</div>
					<div className={styles.row}>
						<Form.Item label="Mobile Number" className={styles.halfWidth} required>
							<PhoneInput valuePropName={{ code: 'mobile_country_code', number: 'mobile_number' }} dependencies={['country_id']} label='Mobile Number' required />
						</Form.Item>
						<Form.Item normalize={normalizeEmail} className={styles.halfWidth} name="email" label="Email" onChange={() => {
							form.validateFields(['email']).then(({ email }) => {
								form.setFieldValue('website', email.split('@').pop())
								//	setWebsite(email.split('@').pop())
							}
							).catch(() => { })
						}}>
							<Input type="email" />
						</Form.Item>
					</div>
					<div className={styles.row}>
						<Form.Item className={styles.halfWidth} name="website" label="&nbsp;&nbsp;Website" >
							<Input type="text" />
						</Form.Item>
						<Form.Item className={styles.halfWidth} name='country_id' label="Country" >
							<CountrySelect onChange={(val, country) => {
								form.setFieldsValue({
									language_id: country.default_language_id,
									currency_id: country.currency_id,
									phone_country_code: country.phone_number_code,
									mobile_country_code: country.phone_number_code,
								})
							}} />
						</Form.Item>
					</div>
					<div className={styles.row}>

						<Form.Item className={styles.thirdWidth} name='language_id' label="Language" dependencies={['country_id']} >
							<LanguageSelect />
						</Form.Item>
						<Form.Item className={styles.thirdWidth} name='currency_id' label="Currency" dependencies={['country_id']} >
							<CurrencySelectDropDown />
						</Form.Item>
						<Form.Item className={styles.thirdWidth} name="iban" label="&nbsp;&nbsp;IBAN">
							<Input type="text" />
						</Form.Item>
					</div>
					<div className={styles.row} >
						<Form.Item className={styles.halfWidth} name='payment_term' label="Payment Term" >
							<Select options={PaymentTerm} fieldNames={{ value: 'id', label: 'name' }} />
						</Form.Item>
						<Form.Item className={styles.halfWidth} name='payment_method_id' label="Payment Method" >
							<Select options={PaymentMethods} fieldNames={{ value: 'id', label: 'name' }} />
						</Form.Item>
					</div>
					<div className={styles.row} >
						<Form.Item className={styles.halfWidth} name='vat_number' label="&nbsp;&nbsp;VAT Number">
							<Input />
						</Form.Item>
						<Form.Item className={styles.halfWidth} name='twinfield_id' label="&nbsp;&nbsp;Twinfield ID">
							<Input disabled />
						</Form.Item>
						<Form.Item className={styles.halfWidth} name='discount' label="Discount %" rules={[
							{
								type: 'number',
								min: 0, // Ensures the discount is not negative
								message: 'Discount cannot be negative',
							},
						]}>
							<Percent type='percent' controls={false} />
						</Form.Item>
					</div>
					<div className={styles.row}>
						<Form.Item className={styles.halfWidth} name='bic_code' label="BIC code" >
							<Input type="text" />
						</Form.Item>
					</div>
				</div>
				<Form.Item name="vendor_type" >
					<Checkbox.Group options={options} className='toggle-token' />
				</Form.Item>
				{!!id && (
					<Form.Item label="Using Portal">
						<Space>
							<Form.Item name="using_portal" valuePropName="checked" noStyle >
								<Checkbox disabled={disabled || !hasUsers} help={"dsadasd"} />
							</Form.Item>
							{!hasUsers && <i>( Add atleast one user to enable portal access. )</i>}
							<Link to={generateRouteUrl('VendorCreateUser', { id })}>
								<Button type="primary" icon={<UserAddOutlined />} disabled={false}>Add new User</Button>
							</Link>
						</Space>
					</Form.Item>
				)}
				{children}
			</Form>

		</div>


	)
}

VendorDetails.propTypes = {
	id: PropTypes.number,
	onSubmit: PropTypes.func,
	getValidationStatus: PropTypes.func,
	disabled: PropTypes.bool,
	hasUsers: PropTypes.bool,
	form: PropTypes.object,
	children: PropTypes.node,
}

export default VendorDetails