import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { searchUsers } from '../../api/customer/account-manager.js';

function AccountManagerSelect({ value = null, onChange, currentSelection = {}, disabled, ...otherProps }) {
    const debounceTimeout = 800
    const [fetching, setFetching] = useState(false);
    const selectedOption = currentSelection?.value && currentSelection.value === value ? [{ value: currentSelection?.value, label: currentSelection?.label }] : []
    const [options, setOptions] = useState([selectedOption]);
    const [searchValue, setSearchValue] = useState('')
    const fetchRef = useRef(0);

    useEffect(() => {
        debounceFetcher()
    }, [])

    const debounceFetcher = useMemo(() => {
        const loadOptions = (value) => {
            fetchRef.current += 1;
            setSearchValue(value)
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);
            // searchUsersByRole(value, [UserRoles.SALES_EMPLOYEE, UserRoles.SALES_MANAGER, UserRoles.SALES_SENIOR]).then((newOptions) => {
            searchUsers(value).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }
                if (!selectedOption.some(({ value }) => value === currentSelection.value)) {
                    setOptions([...selectedOption, ...newOptions]);
                }
                else {
                    setOptions([...newOptions]);
                }
                setFetching(false);
            });
        };
        return debounce(loadOptions, debounceTimeout);
    }, [searchUsers, debounceTimeout]);

    return (
        <Select
            filterOption={false}
            searchValue={searchValue}
            onSearch={(q) => {
                setSearchValue(q)
                debounceFetcher(q)
            }}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            options={options}
            onChange={onChange}
            value={value}
            placeholder="Select Account Manager"
            showSearch
            allowClear
            disabled={disabled}
            {...otherProps}
        />
    );
}

AccountManagerSelect.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    currentSelection: PropTypes.shape({
        value: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired
    })
}

export default AccountManagerSelect;