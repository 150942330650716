import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons'
import { Button, Collapse, Popconfirm, Statistic, Tooltip, message } from 'antd'
import { capitalize, sortBy } from 'lodash-es'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { Link, defer, useActionData, useFetcher, useLoaderData, useNavigate, useParams, useSubmit } from 'react-router-dom'
import { createAddress, deleteAddress, removeAddressDelivery, removeAddressInvoice, setAddressDelivery, setAddressInvoice, setCustomerPrimaryAddress, updateAddress } from '../../api/address'
import { removeContactAuthority, removeContactInvoice, setContactAuthority, setContactInvoice } from '../../api/contact/contact-role.js'
import { createContact, updateContact } from '../../api/contact/contact.js'
import { deleteContact } from '../../api/contact/contact[id].js'
import { getCustomerDetails, setCustomerPrimaryContact } from '../../api/customer'
import { updateCustomerDetails } from '../../api/customer/customer-details.js'
import { HubspotButton } from '../../components/data/hubspot-viewer.jsx'
import { SmallSection } from '../../components/design'
import { Customer, CustomerAddress, CustomerContact, ValidationForm as Form } from '../../components/form'
import SelectInput from '../../components/inputs/SelectInput.jsx'
import { AddressRoles, CustomerAddressRoleSelect } from '../../components/inputs/customer-address-roles-select.jsx'
import { ContactRoles, CustomerContactRoleSelect } from '../../components/inputs/customer-contact-roles-select.jsx'
import { Page } from '../../components/page'
import { ValidationError } from '../../library/classes/exceptions/index.js'
import { ERP_CURRENCY } from '../../library/constants/dynamic.js'
import { generateRouteUrl } from '../../library/constants/index.js'
import { formatPrice } from '../../library/utilities/intl.js'
import styles from '../customers/customer.module.scss'
const { Panel } = Collapse


const CustomerDetails = ({ title }) => {
	const { id: customerId } = useParams()
	const { customerDetails, addresses, contacts } = useLoaderData()
	const actionData = useActionData()
	const [editableSection, setEditableSection] = useState();
	const [addressType, setAddressType] = useState();
	const [contactType, setContactType] = useState();
	const [form] = Form.useForm()

	const submit = useSubmit()
	const fetcher = useFetcher()

	const changeEditableSection = (section = null, subSection = null) => {
		setEditableSection([section, subSection].filter(v => v).join(':'))
	}
	useEffect(() => {
		if (actionData && !actionData.errors) {
			changeEditableSection()
		}
	}, [actionData])

	useEffect(() => {
		if (fetcher.data && !fetcher.data.errors) {
			changeEditableSection()
		}
	}, [fetcher])

	const navigate = useNavigate()
	const addressSubmitButtonRefs = useRef({})
	const contactSubmitButtonRefs = useRef({})
	const handleCustomerDetailsSubmit = async (details) => {
		submit({ ...details, action: "updateBasicDetails" }, {
			method: "POST",
			encType: "application/json"
		})
	}
	const handleNewAddressRoleChange = async (value) => {
		setAddressType(value);
	};
	const handleNewContactRoleChange = async (value) => {
		setContactType(value);
	};

	const handleCustomerAddressSubmit = async (_, address) => {
		const actionType = !address.id ? "addAddress" : "updateAddress";
		if (actionType === "addAddress") {
			fetcher.submit(
				{
					...address,
					context_id: customerId,
					context: 'customer',
					role: addressType,
					action: actionType,
				},
				{
					method: "POST",
					encType: "application/json",
				}
			)
		} else {
			fetcher.submit(
				{
					...address,
					action: actionType
				},
				{
					method: "POST",
					encType: "application/json",
				});
		}
	};

	const handleCustomerContactSubmit = async (_, contact) => {
		const actionType = !contact.id ? "addContact" : "updateContact";
		if (actionType === "addContact") {
			fetcher.submit(
				{
					...contact,
					customer_id: customerId,
					role: contactType,
					action: actionType,
				},
				{
					method: "POST",
					encType: "application/json",
				}
			)
		} else {
			fetcher.submit({ ...contact, action: actionType }, {
				method: "POST",
				encType: "application/json",
			});
		}
	};

	const handleContactRoleChange = ({ contactId, type, action }) => {
		if (!Object.values(ContactRoles).includes(type)) {
			message.error('Invalid action')
			return
		}
		if (!['add', 'remove'].includes(action)) {
			message.error('Invalid action')
			return
		}
		fetcher.submit({
			action: `contact${capitalize(type)}`,
			contactId
		}, {
			method: action === 'add' ? 'post' : 'delete',
			encType: 'application/json'
		})
	}
	const handleAddressRoleChange = ({ addressId, type, action }) => {
		if (!Object.values(AddressRoles).includes(type)) {
			message.error('Invalid action')
			return
		}
		if (!['add', 'remove'].includes(action)) {
			message.error('Invalid action')
			return
		}
		fetcher.submit({
			action: `address${capitalize(type)}`,
			addressId
		}, {
			method: action === 'add' ? 'post' : 'delete',
			encType: 'application/json'
		})
	}

	const getAddressActions = (index, address, isPrimary) => {
		const actions = [
			editableSection === `address:${index}` ?
				<Button
					onClick={() => { addressSubmitButtonRefs.current[index].click() }}
					type="primary"
					shape="circle"
					icon={<SaveOutlined />}
					key="save"
					loading={address.__isLoading ?? false}
				/> :
				<Button
					onClick={() => { changeEditableSection('address', index) }}
					type="primary"
					shape="circle"
					icon={<EditOutlined />}
					key="edit"
				/>
		];

		if (!isPrimary) {
			if (index === "new") {
				actions.push(
					<Button
						type="primary"
						onClick={() => { changeEditableSection('address', null) }}
						shape="circle"
						icon={<DeleteOutlined />}
						danger
						key="delete-new"
					/>
				);
			} else {
				actions.push(
					<Popconfirm
						key="delete"
						title="Are you sure to delete this address?"
						onConfirm={() => {
							fetcher.submit({
								action: "deleteAddress",
								addressId: address.id
							}, {
								method: "POST",
								encType: "application/json"
							})
						}}
					>
						<Button
							type="primary"
							shape="circle"
							icon={<DeleteOutlined />}
							danger
						/>
					</Popconfirm>
				);
			}
		}

		return actions;
	};
	const getContactAction = (index, contact, isPrimary) => {
		const actions = [];

		if (editableSection === `contact:${index}`) {
			actions.push(
				<Tooltip title="Save" key="save">
					<Button onClick={() => contactSubmitButtonRefs.current?.[index]?.click()} type="primary" shape="circle" icon={<SaveOutlined />} loading={contact.__isLoading ?? false} />
				</Tooltip>
			);
		} else {
			actions.push(
				<Tooltip title="Edit" key="edit">
					<Button onClick={() => { changeEditableSection('contact', index) }} type="primary" shape="circle" icon={<EditOutlined />} />
				</Tooltip>
			);
		}

		if (index !== "new" && index !== null && !isPrimary) {
			actions.push(
				<Popconfirm
					key={`delete-${index}`}
					title="Are you sure to delete this contact?"
					onConfirm={() => {
						fetcher.submit({
							action: "deleteContact",
							contactId: contact.id
						}, {
							method: "POST",
							encType: "application/json"
						})
					}}
				>
					<Button type="primary" shape="circle" icon={<DeleteOutlined />} danger />
				</Popconfirm>
			);
		}

		return actions;
	};


	return (
		<Page className='customers-detail' title={title} >
			<div className="headerStats">
				<React.Suspense
					fallback={<Statistic className='card' title="Previous Projects" value='' />}
				>
					<Link type="button" to={`/projects?filter[customer_id]=${customerDetails.id}&view=list`} replace>
						<Statistic
							onClick={() => { navigate(`/projects?filter[customer_id]=${customerDetails.id}&view=list`) }}
							className='card' title="Previous Projects"
							value={customerDetails?.stats?.projects} />
					</Link>
					<Statistic className='card' title="Revenue" value={formatPrice(customerDetails?.stats?.revenue, ERP_CURRENCY)} />
					<Statistic className='card' title="Contacts" value={contacts?.length} />

				</React.Suspense>
			</div>
			<Collapse defaultActiveKey={['1']} expandIconPosition="end">
				<Panel header='Company Details' key="1" extra={
					<div className="actions" onClick={(event) => event.stopPropagation()}>
						{!!customerDetails.hubspot_url && <HubspotButton url={customerDetails.hubspot_url} />}
						<Link to={generateRouteUrl('ProjectCreate', null, {
							customer_id: customerId
						})}>
							<Button>Create Project</Button>
						</Link>
						<Button onClick={() => changeEditableSection('basicDetails')} disabled={editableSection === "basicDetails"}>Edit</Button>
					</div>
				}>


					<Customer form={form} key={customerId} id={Number(customerId)} initialValues={customerDetails} onFinish={(data) => handleCustomerDetailsSubmit(data)} disabled={editableSection !== "basicDetails"} loading={customerDetails.__isLoading}>
						<Button type="primary" htmlType="submit">Save</Button>
						<Button onClick={() => { changeEditableSection() }} htmlType='reset'>Cancel</Button>
					</Customer>
				</Panel>
			</Collapse>
			<Collapse defaultActiveKey={['1']} expandIconPosition="end">
				<Panel header='Addresses' key="1" >
					{addresses.map((address) => (
						<SmallSection
							key={address.id}
							actions={getAddressActions(address.id, address, address.id === customerDetails.primary_address_id)}
							header={(
								<CustomerAddressRoleSelect
									addressId={address.id}
									isPrimary={address.id === customerDetails.primary_address_id}
									isDelivery={address.id === customerDetails.delivery_address_id}
									isInvoice={address.id === customerDetails.billing_address_id}
									onChange={handleAddressRoleChange}
								/>
							)}
						>
							<CustomerAddress index={address.id} initialValues={address} onFinish={(data) => handleCustomerAddressSubmit(address.id, { ...data, id: address.id })} disabled={editableSection !== `address:${address.id}`}>
								<Form.Item style={{ display: 'none' }}>
									<Button htmlType="submit" ref={el => addressSubmitButtonRefs.current[address.id] = el}>Save</Button>
								</Form.Item>
							</CustomerAddress>
						</SmallSection>
					))}
					{editableSection === `address:new` && (
						<SmallSection actions={getAddressActions("new", {})} header={(
							<SelectInput
								name="newAddress"
								defaultValue="role"
								className={styles.selectedValue}
								onChange={handleNewAddressRoleChange}
								options={[
									{ value: 'role', label: 'Set role' },
									{ value: 'primary', label: 'Primary' },
									{ value: 'delivery', label: 'Delivery' },
									{ value: 'invoice', label: 'Invoice' }
								]}
							/>
						)}>
							<CustomerAddress index={"new"} initialValues={{
								country_id: customerDetails?.country_id
							}} onFinish={(data) => handleCustomerAddressSubmit("new", data)}>
								<Form.Item style={{ display: 'none' }}>
									<Button htmlType="submit" ref={el => addressSubmitButtonRefs.current['new'] = el}>Save</Button>
								</Form.Item>
							</CustomerAddress>
						</SmallSection>
					)}
					<Button onClick={() => changeEditableSection('address', 'new')}>Add New Address</Button>
				</Panel>
			</Collapse>
			<Collapse defaultActiveKey={['1']} expandIconPosition="end">
				<Panel header='Contacts' key="1">
					{contacts.map((contact) => (
						<SmallSection
							key={contact.id}
							actions={getContactAction(contact.id, contact, contact.id === customerDetails.primary_contact_id)}
							//primary={contact.id === customerDetails.primary_contact_id} 
							//authority={contact.id !== customerDetails.primary_contact_id && !!contact.is_authority}
							header={(<CustomerContactRoleSelect
								contactId={contact.id}
								isPrimary={contact.id === customerDetails.primary_contact_id}
								isAuthority={!!contact.is_authority}
								isInvoice={contact.id === customerDetails.billing_contact_id}
								onChange={handleContactRoleChange}
							/>)}
						>
							<CustomerContact errors={fetcher.data?.errors} allowedLanguages={customerDetails.entity.default_language} index={contact.id} initialValues={contact} onFinish={(data) => handleCustomerContactSubmit(contact.id, { ...data, id: contact.id })} disabled={editableSection !== `contact:${contact.id}`}>
								<Form.Item style={{ display: 'none' }}>
									<Button htmlType="submit" ref={el => contactSubmitButtonRefs.current[contact.id] = el}>Save</Button>
								</Form.Item>
							</CustomerContact>
						</SmallSection>
					))}
					{editableSection === `contact:new` && (
						<SmallSection actions={getContactAction("new", {})} header={(
							<SelectInput
								name="newAddress"
								defaultValue="role"
								onChange={handleNewContactRoleChange}
								options={[
									{ value: 'role', label: 'Set role' },
									{ value: 'primary', label: 'Primary' },
									{ value: 'authority', label: 'Authority' },
									{ value: 'invoice', label: 'Invoice' }
								]}
							/>
						)}>
							<CustomerContact
								errors={fetcher.data?.errors}
								allowedLanguages={customerDetails.entity.default_language}
								index="new"
								initialValues={{
									phone_country_code: customerDetails.contact_country_code,
									mobile_country_code: customerDetails?.contact_country_code,
								}}
								onFinish={(data) => handleCustomerContactSubmit("new", data)}
							>
								<Form.Item style={{ display: 'none' }}>
									<Button htmlType="submit" ref={el => contactSubmitButtonRefs.current['new'] = el}>Save</Button>
								</Form.Item>
							</CustomerContact>
						</SmallSection>
					)}
					<Button onClick={() => changeEditableSection('contact', 'new')}>Add New Contact</Button>
				</Panel>
			</Collapse>
		</Page>
	)
}
CustomerDetails.propTypes = {
	title: PropTypes.string
}
CustomerDetails.Loader = async ({ params }) => {
	const { id } = params
	const { addresses = [], contacts = [], ...customerDetails } = await getCustomerDetails(id)
	return defer({
		customerDetails,
		addresses: sortBy(addresses, 'id'),
		contacts: sortBy(contacts, 'id'),
	})
}

CustomerDetails.Actions = {

	updateBasicDetails: async ({ params, data: requestData }) => {
		const { id } = params
		try {
			const { message: msg, data } = await updateCustomerDetails(id, requestData)
			message.success(msg)
			return data
		}
		catch (error) {
			if (error instanceof ValidationError) {
				return {
					errors: error.errors
				}
			}
			message.error(error.message)
		}
		return null
	},
	addAddress: async ({ params, data: requestData }) => {
		const { id } = params
		const { role, ...address } = { ...requestData, context_id: id, context: 'customer' }
		try {
			const { message: msg, data } = await createAddress(address)
			message.success(msg);
			if (!data?.id) {
				throw new Error('Unable to add address')
			}
			let msg2 = null
			switch (role) {
				case 'primary':
					msg2 = await setCustomerPrimaryAddress(data.id)
					break;
				case 'delivery':
					msg2 = await setAddressDelivery(id, data.id)
					break;
				case 'invoice':
					msg2 = await setAddressInvoice(id, data.id)
					break;

				default:
					break;
			}
			if (msg2) {
				message.success(msg2);
			}
			return data
		}
		catch (error) {
			if (error instanceof ValidationError) {
				return {
					errors: error.errors
				}
			}
			message.error(error.message)
		}
		return null
	},
	updateAddress: async ({ params, data: requestData }) => {
		const { id } = params
		const { id: addressId, ...address } = { ...requestData, context_id: id, context: 'customer' }
		try {
			const { message: msg, data } = await updateAddress(addressId, address)
			message.success(msg)
			return data
		}
		catch (error) {
			if (error instanceof ValidationError) {
				return {
					errors: error.errors
				}
			}
			message.error(error.message)
		}
		return null
	},
	deleteAddress: async ({ data: requestData }) => {
		const { addressId } = requestData
		try {
			const { message: msg } = await deleteAddress(addressId)
			message.success(msg)
			return true
		}
		catch (error) {
			message.error(error.message)
		}
		return null
	},
	addContact: async ({ params, data: requestData }) => {
		const { id } = params
		const { role, ...contact } = { ...requestData, customer_id: id }
		try {
			const { message: msg, data } = await createContact(contact)
			message.success(msg)

			const { id: contactId } = data
			try {
				switch (role) {
					case 'primary':
						await setCustomerPrimaryContact(contactId)
						break;

					case 'authority':
						await setContactAuthority(contactId)
						break;

					case 'invoice':
						await setContactInvoice(id, contactId)
						break;
				}
			}
			catch (error) {
				message.error(error.message)
				return null
			}
			return data
		}
		catch (error) {
			message.error(error.message)

			if (error instanceof ValidationError) {
				const formattedErrors = {
				}
				Object.entries(error.errors).forEach(([k, v]) => {
					formattedErrors[k] = v
				})

				return {
					errors: formattedErrors
				}
			}
		}
		return null
	},
	updateContact: async ({ params, data: requestData }) => {
		const fieldsToConvert = ["gender_id", "company_role", "language_id"];
		const { id } = params
		const { id: contactId, ...contact } = { ...requestData, customer_id: id }
		fieldsToConvert.forEach(field => {
			if (!isNaN(contact[field])) {
				contact[field] = parseInt(contact[field]);
			}
		});
		try {
			const { message: msg, data } = await updateContact(contactId, contact)
			message.success(msg)
			return data
		}
		catch (error) {
			message.error(error.message)

			if (error instanceof ValidationError) {
				const formattedErrors = {
				}
				Object.entries(error.errors).forEach(([k, v]) => {
					formattedErrors[k] = v
				})

				return {
					errors: formattedErrors
				}

			}



		}
		return null
	},
	deleteContact: async ({ data: requestData }) => {
		const { contactId } = requestData
		try {
			const { message: msg } = await deleteContact(contactId)
			message.success(msg)
			return true
		}
		catch (error) {
			message.error(error.message)
		}
		return null
	},
	contactPrimary: async ({ data: requestData }) => {
		const { contactId } = requestData
		try {
			const { message: msg } = await setCustomerPrimaryContact(contactId)
			message.success(msg)
			return true
		}
		catch (error) {
			message.error(error.message)
		}
		return false
	},
	contactAuthority: async ({ data: requestData, method }) => {
		const { contactId } = requestData
		if (method === 'DELETE') {
			try {
				const { message: msg } = await removeContactAuthority(contactId)
				message.success(msg)
				return true
			}
			catch (error) {
				message.error(error.message)
			}
			return false
		}
		else {
			try {
				const { message: msg } = await setContactAuthority(contactId)
				message.success(msg)
				return true
			}
			catch (error) {
				message.error(error.message)
			}
			return false
		}
	},
	contactInvoice: async ({ params, data: requestData, method }) => {
		const { id: customerId } = params
		const { contactId } = requestData
		if (method === 'DELETE') {
			try {
				const { message: msg } = await removeContactInvoice(customerId, contactId)
				message.success(msg)
				return true
			}
			catch (error) {
				message.error(error.message)
			}
			return false
		}
		else {
			try {
				const { message: msg } = await setContactInvoice(customerId, contactId)
				message.success(msg)
				return true
			}
			catch (error) {
				message.error(error.message)
			}
			return false
		}
	},
	addressPrimary: async ({ data: requestData }) => {
		const { addressId } = requestData
		try {
			const msg = await setCustomerPrimaryAddress(addressId)
			message.success(msg)
			return true
		}
		catch (error) {
			message.error(error.message)
		}
		return false
	},
	addressDelivery: async ({ params, data: requestData, method }) => {
		const { id: customerId } = params
		const { addressId } = requestData
		if (method === 'DELETE') {
			try {
				const { message: msg } = await removeAddressDelivery(customerId, addressId)
				message.success(msg)
				return true
			}
			catch (error) {
				message.error(error.message)
			}
			return false
		}
		else {
			try {
				const msg = await setAddressDelivery(customerId, addressId)
				message.success(msg)
				return true
			}
			catch (error) {
				message.error(error.message)
			}
			return false
		}
	},
	addressInvoice: async ({ params, data: requestData, method }) => {
		const { id: customerId } = params
		const { addressId } = requestData
		if (method === 'DELETE') {
			try {
				const { message: msg } = await removeAddressInvoice(customerId, addressId)
				message.success(msg)
				return true
			}
			catch (error) {
				message.error(error.message)
			}
			return false
		}
		else {
			try {
				const msg = await setAddressInvoice(customerId, addressId)
				message.success(msg)
				return true
			}
			catch (error) {
				message.error(error.message)
			}
			return false
		}
	}
}
export default CustomerDetails