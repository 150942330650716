import { FilterOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Drawer, Input, Space } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { generatePath, useLoaderData, useNavigate } from 'react-router-dom';
import { getAlCustomers } from '../../api/customer';
import { SelectedFilters } from '../../components/data/selected-filters.jsx';
import CustomerFilterForm from '../../components/form/customer-filter/customer-filter.jsx';
import { Page } from '../../components/page';
import { Table } from '../../components/table';
import useFetch from '../../hooks/use-fetch.js';
import { CustomerColumns } from '../../library/constants/tableColumns/customerColumns';
import { searchParamsToObject } from '../../library/helpers';

const FILTER_NAMES = {
  company: 'Company',
  email: 'Email',
  industry: 'Industry',
  country_id: 'Country',
  language_id: 'Language',
  entity_id: 'Executive Entity',
  website: 'Website',
}

function Customers(props) {
  const initialData = useLoaderData()
  const navigate = useNavigate()
  const [filtersVisible, setFiltersVisible] = useState(false)
  const [list, hasMore, isLoading, searchParamsParsed, {
    setSort,
    setFilters,
    loadMore
  }] = useFetch({ initialData })

  const handleRowClick = (_, record) => {
    navigate(generatePath('/customers/:id', {
      id: record.id
    }))
  }

  return (
    <Page className='customers-list' title={props.title} backLink={false} summary={initialData?.meta?.totalCount ? `(${initialData?.meta?.totalCount} Records)` : '(No Records)'} header={
      <>
        <div className=""></div>
        <Space.Compact style={{ width: '100%', maxWidth: '400px' }}>
          <Input.Search placeholder="Search" defaultValue={searchParamsParsed?.filter?.search} prefix={<SearchOutlined />}
            onChange={(e) => setFilters({ search: e.target.value })
            } allowClear />
          <Button onClick={() => setFiltersVisible(v => !v)} icon={<FilterOutlined />}>Filters</Button>
        </Space.Compact>
        <Button type="primary" onClick={() => { navigate('/customers/create') }} icon={<PlusOutlined />}>Add new Company</Button>
      </>
    } subHeader={initialData.meta?.filters && <SelectedFilters filters={initialData.meta.filters} filterNames={FILTER_NAMES} updateFilters={setFilters} />} >
      <Drawer open={filtersVisible} onClose={() => setFiltersVisible(false)} >
        <CustomerFilterForm key={JSON.stringify(searchParamsParsed.filter)} data={searchParamsParsed.filter} onFinish={setFilters} />
      </Drawer>
      <Table
        columns={CustomerColumns}
        data={list}
        /* perPage={Number(meta?.limit)} currentPage={Number(meta?.page)} total={Number(meta?.totalCount)} */
        isLoading={isLoading}
        onChange={setSort}
        onRowClick={handleRowClick}
        hasMore={hasMore}
        loadMore={loadMore}
      />
    </Page>
  )
}

Customers.Loader = async ({ request }) => {
  const url = new URL(request.url)
  const { filter, page, sort } = searchParamsToObject(url.searchParams)
  const { data, meta } = await getAlCustomers({ filter, page, ...sort })
  //console.log({ Loading: data.map(r => r.id) })
  return { data, meta }
}
Customers.propTypes = {
  title: PropTypes.string
}
export default Customers
