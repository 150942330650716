// Each file here is tied to the routes defined in the routes folder.
// All API calls originating from that page can be put here

/* eslint-disable no-undef */
import { pick } from 'lodash-es';
import { axiosInstance } from '../common';

// Define all api endpoint urls at the top
const ENDPOINT = '/address';

export const createAddress = (payload) => {
    payload = pick(payload, ['street', 'street_number', 'zipcode', 'additional_field_1', 'additional_field_2',
        'city', 'country_id', 'context', 'context_id', 'county']);
    return axiosInstance.post(ENDPOINT, payload)
}