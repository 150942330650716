import { CloseOutlined, DeleteOutlined, InboxOutlined, PlusOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Form, Input, InputNumber, message, Select, Statistic } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Link, redirect, replace, useFetcher, useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { getConversionRates } from '../../api/common/common.js';
import { createProjectCancellationInvoice, getProjectCancellationInvoice, getProjectDetails, sendCancellationWaiveOffRequest } from '../../api/project/project.js';
import { Box } from '../../components/design/box.js';
import SmallSection from '../../components/design/small-section.jsx';
import Currency from '../../components/inputs/Currency.jsx';
import CurrencyConversion from '../../components/inputs/currency-conversion.jsx';
import { InputWrapper } from '../../components/inputs/input-wrappers.jsx';
import Confirm from '../../components/message/Confirm.jsx';
import { Prompt } from '../../components/message/prompt.jsx';
import Page from '../../components/page/page.jsx';
import { useInvoiceLineTotals } from '../../hooks/use-invoiceline-totals.js';
import { ValidationError } from '../../library/classes/exceptions/index.js';
import { ERP_CURRENCY, VatTypes } from '../../library/constants/dynamic.js';
import { getProjectStatusByID, ProjectStatuses } from '../../library/constants/project-statuses.js';
import { generateRouteUrl } from '../../library/constants/routes.js';
import { getFormattedId } from '../../library/helpers/index.js';
import { formatPrice } from '../../library/utilities/intl.js';
import styles from './project-cancellation-invoice.module.scss';

const InvoiceLineForm = ({ name, field, onDelete, conversionRate, projectCurrencyCode/* ,  errors */, entityId }) => {
    const {
        totalPriceInclVat,
        setQuantity,
        setTotalPriceExclVat,
        setVisibleVatId,
        unitPrice,
    } = useInvoiceLineTotals(0, 0, 1, 1)
    const projectCurrencyDiffers = projectCurrencyCode !== ERP_CURRENCY
    const projectCurrencyRate = conversionRate?.[projectCurrencyCode];

    return (
        <SmallSection>
            <div className={styles.invoiceLineRow}>
                <Form.Item
                    {...field}
                    name={[name, 'description']}
                    rules={[
                        {
                            required: true,
                            message: 'Description is required',
                        },
                    ]}
                >
                    <Input.TextArea />
                </Form.Item>
                <Form.Item
                    {...field}
                    name={[name, 'quantity']}
                    rules={[
                        {
                            required: true,
                            message: 'Quantity is required',
                        },
                    ]}
                >
                    <InputNumber controls={false} onChange={v => setQuantity(v)} />
                </Form.Item>
                <Form.Item>
                    {projectCurrencyDiffers ? (
                        <CurrencyConversion currency={projectCurrencyCode} secondCurrency={ERP_CURRENCY} conversionRate={1 / projectCurrencyRate} value={unitPrice} disabled />
                    ) : (
                        <Currency currency={ERP_CURRENCY} value={unitPrice} disabled />
                    )}
                </Form.Item>
                <Form.Item
                    {...field}
                    name={[name, 'total_price_excl_vat']}
                    rules={[
                        {
                            required: true,
                            message: 'Total Amount Excl. VAT is required',
                        },
                    ]}
                >
                    {projectCurrencyDiffers ? (
                        <CurrencyConversion currency={projectCurrencyCode} secondCurrency={ERP_CURRENCY} conversionRate={1 / projectCurrencyRate} onChange={v => setTotalPriceExclVat(v)} />
                    ) : (
                        <Currency currency={ERP_CURRENCY} onChange={v => setTotalPriceExclVat(v)} />

                    )}
                </Form.Item>
                <Form.Item
                    {...field}
                    name={[name, 'visible_vat']}
                    rules={[
                        {
                            required: true,
                            message: 'Pease select VAT Type',
                        },
                    ]}
                >
                    <Select
                        options={VatTypes.filter(({ entity_id }) => entity_id === entityId)}
                        fieldNames={{ value: 'id', label: 'description' }}
                        onChange={c => setVisibleVatId(c)}
                    />
                </Form.Item>
                <Form.Item>
                    {projectCurrencyDiffers ? (
                        <CurrencyConversion currency={projectCurrencyCode} secondCurrency={ERP_CURRENCY} conversionRate={1 / projectCurrencyRate} value={totalPriceInclVat} disabled />
                    ) : (
                        <Currency currency={ERP_CURRENCY} value={totalPriceInclVat} disabled />

                    )}
                </Form.Item>
                <Button
                    type="squarepanel"
                    onClick={() => onDelete(name)}
                    shape='square'
                    icon={<DeleteOutlined />}
                    danger
                    ghost
                />
            </div>
        </SmallSection>
    )
}

InvoiceLineForm.propTypes = {
    // children: PropTypes.node,
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    field: PropTypes.object.isRequired,
    conversionRate: PropTypes.object,
    projectCurrencyCode: PropTypes.string,
    entityId: PropTypes.number.isRequired,
    errors: PropTypes.object,
    onDelete: PropTypes.func,
}

const ProcjectCancellationInvoice = ({ title }) => {
    const { projectId } = useParams()
    const { projectDetails, selectedItems, conversionRates, cancellationReason } = useLoaderData()
    const [form] = Form.useForm()
    const fetcher = useFetcher()
    const navigate = useNavigate()

    return (
        <Page className='projects-create' title={title} backLink={generateRouteUrl('ProjectUpdate', { projectId })} header={
            <div className='headerStats'>
                <Statistic
                    title="Project ID"
                    value={getFormattedId(projectId)}
                    formatter={v => v}
                    valueStyle={{
                        color: '#5A5A5A',
                        backgroundColor: '#E6E6E6'
                    }}
                />
                <Statistic
                    title="Project Status"
                    value={projectDetails.status.name}
                />
                <Link to={generateRouteUrl('CustomerDetails', {
                    id: projectDetails.customer_id
                })}>
                    <Statistic
                        title="Customer"
                        value={projectDetails.customer.company}
                    />
                </Link>
                {!!projectDetails.order_manager && (
                    <Statistic
                        title="Order Manager"
                        value={projectDetails.order_manager.name}
                    />
                )}
            </div>}>
            <Box
                icon={<InboxOutlined style={{ fontSize: '20px', color: 'red' }} />}
                header={
                    <div className={styles.headingText}>
                        Create Cancellation Invoice
                    </div>
                }
            >
                <Box type={Box.BoxTypes.GRAY} header={
                    <div className={styles.invoiceLineRow}>
                        <h4>Description</h4>
                        <h4>Quantity</h4>
                        <h4>Unit Price</h4>
                        <h4>Total Price Excl. VAT</h4>
                        <h4>VAT</h4>
                        <h4>Total Price Incl. VAT</h4>
                        <h4></h4>
                    </div>
                }>
                    {selectedItems.map(item => (
                        <SmallSection key={item.item_id}>
                            <div className={styles.invoiceLineRow}>
                                <div>{item.description}</div>
                                <div>{item.quantity}</div>
                                <div>{formatPrice(item.total_price_excl_vat / item.quantity, ERP_CURRENCY)}</div>
                                <div>{formatPrice(item.total_price_excl_vat, ERP_CURRENCY)}</div>
                                <div>{VatTypes.find(({ id }) => id === item.visible_vat)?.description}</div>
                                <div>{formatPrice(item.total_price_incl_vat, ERP_CURRENCY)}</div>
                            </div>
                        </SmallSection>
                    ))}
                    <Form
                        name="invoice"
                        onFinish={(data) => {
                            fetcher.submit({
                                ...data,
                                reason: cancellationReason,
                                item_ids: selectedItems.map(({ item_id }) => item_id),
                                action: 'cancelAndSendInvoice'
                            }, {
                                method: 'post',
                                encType: 'application/json',
                            })
                        }}
                        form={form}
                    >
                        <Form.List name="lines">
                            {(fields, { add, remove }, { errors }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <InvoiceLineForm
                                            key={key}
                                            name={name}
                                            field={restField}
                                            errors={{ ...fetcher.data?.errors?.[key], ...errors }}
                                            projectCurrencyCode={projectDetails.currency?.code}
                                            conversionRate={conversionRates}
                                            entityId={projectDetails.entity_id}
                                            onDelete={remove}
                                        />
                                    ))}
                                    <div className="actions align--right">
                                        <Button type="primary" ghost icon={<PlusOutlined />} onClick={() => add()}>Add Line</Button>
                                    </div>
                                    <br />
                                </>
                            )}
                        </Form.List>
                        <Form.Item name="footer_text" label="Footer Text">
                            <Input.TextArea />
                        </Form.Item>
                    </Form>
                </Box>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className='actions'>
                        <Prompt
                            onConfirm={(data) => {
                                fetcher.submit({
                                    ...data,
                                    reason: cancellationReason,
                                    item_ids: selectedItems.map(({ item_id }) => item_id),
                                    action: 'sendWaiveOfRequest'
                                }, {
                                    method: 'post',
                                    encType: 'application/json',
                                })
                            }}
                            width={720}
                            type="link"
                            buttonText="Request Cancellation fee waive off"
                            okText="Send Request"
                            cancelText="Cancel"
                        >
                            <p>Please specify the reason you are considering waiving off the cancellation fee for this client. Your request will be reviewed by the senior staff.</p>
                            <InputWrapper name="comment" textarea rows={10} />
                        </Prompt>
                    </div>
                    <div className='actions'>
                        <Confirm
                            content='You will lose unsaved changes. Do you want to go back ?'
                            onConfirm={() => {
                                navigate(-1)
                            }}
                            icon={<CloseOutlined />}
                            type="danger"
                            ghost
                        >Cancel</Confirm>
                        <Confirm
                            title="Cancel Project And Send Cancellation Fee Invoice to Customer"
                            content="The selected items will be cancelled and the client will be notified. An invoice for the cancellation fee will be also sent to the customer"
                            okText='Continue'
                            cancelText='Cancel'
                            width={600}
                            onConfirm={() => {
                                form.submit()
                            }}
                            loading={fetcher.json?.action === 'previewQuotation'}
                            icon={<SendOutlined />}
                            type="primary"
                            danger
                        >Cancel and Send Invoice</Confirm>
                    </div>
                </div>
            </Box>
        </Page>
    )
}

ProcjectCancellationInvoice.propTypes = {
    title: PropTypes.string
}

ProcjectCancellationInvoice.Actions = {
    cancelAndSendInvoice: async ({ params, data }) => {
        const { projectId } = params
        const { item_ids, lines, reason, footer_text } = data
        try {
            const msg = await createProjectCancellationInvoice(projectId, item_ids, reason, lines, footer_text)
            message.success(msg)
            return replace(generateRouteUrl('ProjectUpdate', {
                projectId
            }))
        }
        catch (error) {
            message.error(error.message)
            if (error instanceof ValidationError) {
                return {
                    errors: error.errors
                }
            }
        }
        return false

    },
    sendWaiveOfRequest: async ({ params, data }) => {
        const { projectId } = params
        const { item_ids, reason, comment } = data
        try {
            const msg = await sendCancellationWaiveOffRequest(projectId, item_ids, reason, comment)
            message.success(msg)
            return replace(generateRouteUrl('ProjectUpdate', {
                projectId
            }))
        }
        catch (error) {
            message.error(error.message)
            if (error instanceof ValidationError) {
                return {
                    errors: error.errors
                }
            }
        }
        return false

    },
}

ProcjectCancellationInvoice.Loader = async ({ params, request }) => {
    const { projectId } = params
    const url = new URL(request.url)
    const { items, comment } = Object.fromEntries(url.searchParams) || {}

    if (!items) {
        return redirect('..')
    }

    //Parsed items 
    const parsedItems = items.split(',').map(Number).filter(n => !isNaN(n))

    const projectDetails = await getProjectDetails(projectId)
    if (projectDetails.status !== ProjectStatuses.CONFIRMATION_CLIENT_SENT) {
        return replace('..')
    }

    projectDetails.status = getProjectStatusByID(projectDetails.status == null ? 1 : projectDetails.status)

    const selectedItems = await getProjectCancellationInvoice(projectId, parsedItems)
    if (!selectedItems || selectedItems.length !== parsedItems.length) {
        return redirect('..')
    }

    const conversionRates = await getConversionRates()
    return {
        projectDetails,
        selectedItems,
        cancellationReason: comment,
        conversionRates
    }
}

export default ProcjectCancellationInvoice