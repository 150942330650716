import { GlobalOutlined, MailOutlined, MobileOutlined } from '@ant-design/icons';
import { Input, Spin } from 'antd';
import { debounce } from 'lodash-es';
import PropTypes from 'prop-types';
import React from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { getStudioList, getStudioOrdersKanabanCount } from '../../api/studio/studio';
import { fetchSmallWebsiteLogo } from '../../components/design';
import KanabanBoard from '../../components/design/kanaban';
import styles from '../../components/form/project/project-details.module.scss';
import { Page } from '../../components/page';
import { useFetch } from '../../hooks';
import { generateRouteUrl, Server } from '../../library/constants';
import { StudioKanabanColumn } from '../../library/constants/kanabanColumns/studioKanabanColumn';
import { getFormattedId, searchParamsToObject } from '../../library/helpers';
import { formatDate } from '../../library/utilities/intl';

const getSourceIcon = (source) => {
    switch (source) {
        case 'email':
            return <MailOutlined className={styles.mail} />
        case 'website':
            return <GlobalOutlined className={styles.globe} />
        case 'phone':
            return <MobileOutlined className={styles.phone} />
    }
}

const renderStudioKanabanCard = ({ item, onClick }) => {
    return (

        <div className='kanaban__card' onClick={() => onClick(item)}>
            {!!item.deadline_delivery_text && (
                <div className="kanaban__card__header">
                    <span
                        className={`priority ${item.deadline_delivery_text === "upcoming" ? "upcoming" :
                            item.deadline_delivery_text === "passed" ? "delayed" : ""
                            }`}
                    >
                        {item.deadline_delivery_text === "upcoming"
                            ? "UPCOMING DEADLINE"
                            : item.deadline_delivery_text === "passed"
                                ? "DELAYED ITEMS"
                                : ""}
                    </span>
                </div>
            )}
            {!!item.client_expected_delivery_date && (
                <div className="kanaban__card__header">
                    <p className='priority'>Delivery Deadline :  {formatDate(String(item.client_expected_delivery_date))}</p>
                </div>
            )}
            <div className="kanaban__card__body">
                <div className="source">
                    <div className="numberIcon">
                        <span className="project-number">{getFormattedId(item.id)}</span>
                        <span className="project-source">{getSourceIcon(item.project.source_id)}</span>
                    </div>
                    <span className="project-country">
                        <img src={`${Server.BaseUrl}/${item.project.customer.country.flag_url}`} alt={item.project.customer.country.name} title={item.project.customer.country.name} />
                    </span>
                </div>
                <span className="customer-logo">
                    {fetchSmallWebsiteLogo(item.project.customer.website, item.project.customer.company)}
                </span>

                <div className='itemName'>
                    {item.product.name}
                </div>

                <div className="details">
                    <p className='head'>Account Mananger</p>
                    <p className='value'>{item.project.user?.name}</p>
                </div>
                <div className="kanaban__card__footer">
                    <span className='payment'>Order Manager  : <span className='payment_value'> {item.project.order_manager?.name}</span></span>
                </div>
            </div>
        </div>
    );
};

const StudioKanabanView = ({ data, isLoading = false, hasMore, onLoadMore, onItemClick, kanabanCount }) => {
    const columns = StudioKanabanColumn.map(column => ({
        ...column,
        items: data.filter(({ studio_status }) => studio_status === column.key) ?? [],
        count: kanabanCount?.find(({ order_status }) => order_status === column.key)?.count

    }))

    if (isLoading) {
        return (
            <Spin />
        )
    }

    return (
        <KanabanBoard
            columns={columns}
            onItemClick={onItemClick}
            renderItem={renderStudioKanabanCard}
            hasMore={hasMore}
            loadMore={onLoadMore}
        />
    )
}

StudioKanabanView.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    isLoading: PropTypes.bool,
    hasMore: PropTypes.bool.isRequired,
    onLoadMore: PropTypes.func.isRequired,
    onItemClick: PropTypes.func.isRequired,
    kanabanCount: PropTypes.arrayOf(PropTypes.object).isRequired,
}

function StudioList(props) {
    const initialData = useLoaderData()
    const navigate = useNavigate()

    const [list, hasMore, isLoading, searchParamsParsed, {
        setSort,
        setFilters,
        loadMore
    }] = useFetch({ initialData })

    const handleRowClick = (record) => {
        navigate(generateRouteUrl('StudioItemDetails', {
            id: record.id
        }))
    }

    const debouncedChangeHandler = debounce(q => setFilters({ search: q }), 500)

    return (
        <Page className='quotation-list' title={props.title} backLink={false} header={
            <>
                <div>
                </div>
                <div className={styles.search}>
                    <Input.Search placeholder="Search" onChange={(e) => debouncedChangeHandler(e.target.value)} defaultValue={searchParamsParsed?.filter?.search ?? ''} allowClear />
                </div>
            </>
        }> <StudioKanabanView data={list} kanabanCount={initialData.kanabanCount} isLoading={isLoading} hasMore={hasMore} onChangeSort={setSort} onLoadMore={loadMore} onItemClick={handleRowClick} />

        </Page>
    )
}
StudioList.Loader = async ({ request }) => {
    const url = new URL(request.url)
    const { filter, page, sort } = searchParamsToObject(url.searchParams)
    const { data, meta } = await getStudioList({ filter, page, ...sort })

    //Loads kanaban count only in list view mode and on first request
    let kanabanCount = null
    if (!page) {
        kanabanCount = await getStudioOrdersKanabanCount({ filter, limit: 50 })
    }

    return { data, meta, kanabanCount }

}

StudioList.propTypes = {
    title: PropTypes.string,
}

export default StudioList
