import { DeleteOutlined } from '@ant-design/icons'
import { Button, Checkbox, Rate, Table, Tag, message } from 'antd'
import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import { redirect, useActionData, useLoaderData, useSubmit } from 'react-router-dom'
import { getCombiSuggestionDetails, sendCombiRequest } from '../../../api/combi'
import { getProductVendors } from '../../../api/product/product.js'
import { Box } from '../../../components/design/box.js'
import { DatePicker, FieldWrapper, GeneralFieldWrapper, InputWrapper } from '../../../components/inputs'
import Confirm from '../../../components/message/Confirm.jsx'
import { Page } from '../../../components/page'
import { useUpdateQueryStringValueWithoutNavigation } from '../../../hooks/use-update-query-string-without-navigation.js'
import { NotFoundError, ValidationError } from '../../../library/classes/exceptions/index.js'
import { generateRouteUrl } from '../../../library/constants/routes.js'
import { Server } from '../../../library/constants/server.js'
import { arrayIntersection } from '../../../library/utilities/common.js'
import { formatDate } from '../../../library/utilities/intl'

const RequestReview = ({ selectedVendors, comment, deliveryDeadline, quantity }) => (
    <>
        <p>A review request will be sent to selected vendors for selected items</p>
        <Box type={Box.BoxTypes.BLUE}>
            <div className="form-fields-wrapper form-fields-wrapper--descriptions" style={{ textAlign: 'left' }}>
                <GeneralFieldWrapper label="Quantity" horizontal>
                    <span>{quantity}</span>
                </GeneralFieldWrapper>
                <GeneralFieldWrapper label="Delivery Deadline Date" span={2} horizontal><span>{formatDate(deliveryDeadline)}</span></GeneralFieldWrapper>
                <GeneralFieldWrapper label="Selected Vendors" span={3}>
                    {selectedVendors.map((vendor, index) => (
                        <div key={vendor.vendor_id}>{index + 1}. {vendor.company}</div>
                    ))}
                </GeneralFieldWrapper >
                <GeneralFieldWrapper label="Notes" span={3}><span>{comment}</span></GeneralFieldWrapper>
            </div>
        </Box>
    </>
)
RequestReview.propTypes = {
    selectedVendors: PropTypes.arrayOf(PropTypes.object).isRequired,
    comment: PropTypes.string,
    deliveryDeadline: PropTypes.string,
    quantity: PropTypes.number.isRequired,
}

const getSelectedQuantity = (items, selectedIds) => items.reduce((sum, { id, approved_quantity }) => (selectedIds.includes(id) ? sum + approved_quantity : sum), 0)

const CombiDealsDetails = ({ title }) => {
    const { combiDetails, vendors } = useLoaderData()
    const [selectedItemsString, setSelectedItems] = useUpdateQueryStringValueWithoutNavigation('selectedItems')
    const selectedItems = (!selectedItemsString || !selectedItemsString.length) ? [] : selectedItemsString.split(',').map(Number)
    const [selectedVendors, setSelectedVendors] = useState([])
    const [comment, setComment] = useState(null)
    const [deadlineDate, setDeadlineDate] = useState(null)
    const submit = useSubmit()
    const { errors = {} } = useActionData() || {}
    const availableVendors = useMemo(() => {
        const filteredVendors = vendors ?? []
        //Create array of selected unique languages based on items
        const selectedItemLanguages = [... new Set(combiDetails.items
            ?.filter(({ id }) => selectedItems.includes(id))
            ?.map(({ language }) => language))]

        if (!selectedItemLanguages || !selectedItemLanguages.length) {
            return filteredVendors
        }
        return filteredVendors.filter(({ language }) => arrayIntersection(language, selectedItemLanguages).length)
    }, [combiDetails.items, vendors, selectedItems])

    const handleReviewRequest = () => {
        submit({
            itemIds: selectedItems,
            vendorIds: selectedVendors,
            deadlineDate,
            comment,
            action: 'sendCombiRequest'
        }, {
            method: 'post',
            encType: 'application/json',
            replace: true
        })
    }

    return (
        <Page title={title}>
            <div style={{ display: 'grid', gridTemplateColumns: '7fr 3fr', gap: '1rem' }}>
                <Box header={<h3>{combiDetails?.product_name}</h3>}>
                    <Box type={Box.BoxTypes.GRAY}>
                        <Table
                            columns={[
                                {
                                    title: 'Project ID',
                                    dataIndex: 'project_id',
                                },
                                {
                                    title: 'Country/Language',
                                    dataIndex: 'language',
                                    render: lang => <><img
                                        key={lang}
                                        src={Server.getPublicFileUrl(`upload/flag/${lang}.png`)}
                                        width={16}
                                        height={16}
                                    /> <Tag>{lang}</Tag> </>
                                },
                                {
                                    title: 'Quantity',
                                    dataIndex: 'approved_quantity',
                                },
                                {
                                    title: 'Order Date',
                                    dataIndex: 'createdAt',
                                    render: formatDate
                                },
                                {
                                    title: 'Delivery Deadline Date',
                                    dataIndex: 'client_expected_delivery_date',
                                },
                                {
                                    title: 'Delete',
                                    key: 'action',
                                    render: () => <Button
                                        icon={<DeleteOutlined />}
                                        onClick={e => e.stopPropagation()}
                                        type='actionpanel'
                                        shape='square'
                                        ghost
                                        danger
                                    />,
                                },
                            ]}
                            rowKey="id"
                            dataSource={combiDetails?.items}
                            pagination={false}
                            rowSelection={{
                                selectedRowKeys: selectedItems,
                                onChange: (selectedIds) => {
                                    setSelectedItems(selectedIds.join(','))
                                },
                            }}
                            onRow={(record) => ({
                                onClick: event => {
                                    event.preventDefault()
                                    if (selectedItems.includes(record.id)) {
                                        setSelectedItems(selectedItems.filter(a => a !== record.id).join(','))
                                    }
                                    else {
                                        setSelectedItems([...selectedItems, record.id].join(','))
                                    }
                                }
                            })}
                        />
                    </Box>
                    <h3>Suggested Vendors</h3>
                    <Checkbox.Group className='toggle-token'
                        options={availableVendors.map(vendor => ({
                            label: (
                                <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'baseline' }}>
                                    {vendor.company}
                                    <Rate value={vendor.rating} disabled />
                                    <div>
                                        {vendor.language.map(code => <Tag key={code}>{code}</Tag>)}
                                    </div>
                                </div>
                            ),
                            value: vendor.vendor_id
                        }))}
                        value={[...selectedVendors]}
                        onChange={val => setSelectedVendors(val)}
                    />
                    <br />
                    <InputWrapper label="Notes" name="comment" onChange={e => setComment(e.target.value)} textarea allowClear errors={errors} />
                </Box>
                <Box header={<h3>Summary</h3>}>
                    <div className="form-fields-wrapper--horizontal">
                        <GeneralFieldWrapper label="Selected Quantity">
                            {getSelectedQuantity(combiDetails.items, selectedItems)} / {combiDetails?.total_quantity}
                        </GeneralFieldWrapper>
                        <GeneralFieldWrapper label="Selected Orders">
                            {selectedItems.length} / {combiDetails.items?.length}
                        </GeneralFieldWrapper>
                        {/* <GeneralFieldWrapper label="Requested Date">
                            <Input value={formatDate(combiDetails.createdAt)} disabled />
                        </GeneralFieldWrapper> */}
                        <FieldWrapper name='expected_delivery_date' label="Delivery Deadline" errors={errors} showRequired={true}>
                            <DatePicker name="expected_delivery_date" value={deadlineDate} onChange={(val) => setDeadlineDate(val)} />
                        </FieldWrapper>
                        <GeneralFieldWrapper label="Selected Vendors">
                            <ol>
                                {selectedVendors.map(vendorId => (
                                    <li key={vendorId}>{availableVendors.find(({ vendor_id }) => vendor_id === vendorId)?.company}</li>
                                ))}
                            </ol>
                        </GeneralFieldWrapper>
                    </div>
                    <br />
                    <div className="actions align--right">
                        <Confirm
                            title='Send revew request'
                            width={720}
                            content={(
                                <RequestReview
                                    selectedVendors={availableVendors.filter(({ vendor_id }) => selectedVendors.includes(vendor_id))}
                                    quantity={getSelectedQuantity(combiDetails.items, selectedItems)}
                                    deliveryDeadline={deadlineDate}
                                    comment={comment}
                                />
                            )}
                            modalIcon={false}
                            type='primary'
                            onConfirm={handleReviewRequest}
                            disabled={!selectedItems.length || !selectedVendors.length || !deadlineDate}
                        >Review Request</Confirm>
                    </div>
                </Box>
            </div>
        </Page>
    )
}

CombiDealsDetails.propTypes = {
    title: PropTypes.string
}

CombiDealsDetails.Actions = {
    sendCombiRequest: async ({ data }) => {
        const { itemIds, vendorIds, deadlineDate, comment } = data
        try {
            const { message: msg, data } = await sendCombiRequest(itemIds, vendorIds, deadlineDate, comment)
            message.success(msg)
            return redirect(generateRouteUrl('SentCombiDetails', {
                id: data
            }))
        }
        catch (error) {
            message.error(error.message)
            if (error instanceof ValidationError) {
                return {
                    errors: error.errors
                }
            }
        }
        return null
    },
}

CombiDealsDetails.Loader = async ({ params }) => {
    const { productId } = params
    const combiDetails = await getCombiSuggestionDetails(productId)
    if (!combiDetails) {
        throw new NotFoundError('Invalid Combi Deal')
    }
    const vendors = await getProductVendors(combiDetails.product_id)
    return { combiDetails, vendors }
}

export default CombiDealsDetails