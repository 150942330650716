import {
    CheckOutlined,
    CloseOutlined
} from '@ant-design/icons';
import { Button, Descriptions, Drawer, Modal, Radio, message } from 'antd';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { Form, redirect, useNavigate, useNavigation, useRouteLoaderData, useSubmit } from 'react-router-dom';
import { createPaymentLink } from '../../api/finance/invoice.js';
import { Currency, FieldWrapper } from '../../components/inputs/index.js';
import { ERP_CURRENCY } from '../../library/constants/dynamic.js';
import { PaymentMethods } from '../../library/constants/payment-methods.js';
import { convertFromERPCurrency, formatDate, formatDateTime, formatPrice } from '../../library/utilities/intl.js';
import Styles from './create-payment-link.module.scss';

const CreatePaymentLink = () => {
    const { invoiceDetails } = useRouteLoaderData('InvoiceDetails')
    const navigate = useNavigate()
    const submit = useSubmit();
    const navigation = useNavigation();
    const formRef = useRef()

    return (
        <Modal
            title="Create Payment Link"
            open={true}
            onCancel={() => navigate('..', { replace: true })}
            width='40%'
            forceRender
            destroyOnClose
            footer={<>
                <Button type="primary" icon={<CheckOutlined />} onClick={() => {
                    submit(formRef.current)
                }} loading={navigation.state === 'submitting'}>Save</Button>
                <Button type="cancel" icon={<CloseOutlined />} onClick={() => navigate('..', { replace: true })}>Cancel</Button>
            </>}>
            <Form method="post" className={Styles.formWrapper} ref={formRef} replace>
                <div className={Styles.mainWrapper}>
                    <FieldWrapper name="payment_method">
                        <Radio.Group name="payment_method" className='toggle-token' defaultValue={1}>
                            <Radio value={1}>1 (iDeal, Sofort 0,60)</Radio>
                            <Radio value={2}>2 (1 + Mastercard, Maestro, VISA, Bancontact,CarteBleue 2.4%)</Radio>
                            <Radio value={3}>3 (2 + PayPal 3.4%)</Radio>
                        </Radio.Group>
                    </FieldWrapper>
                    <FieldWrapper name="amount" label="Total Price (Inl. VAT)" horizontal>
                        <Currency currency={invoiceDetails.currency?.code} name="amount" defaultValue={invoiceDetails.amount_open} />
                    </FieldWrapper>
                </div>
            </Form>
        </Modal>
    )
}

CreatePaymentLink.Action = async ({ params, request }) => {
    const { payment_method, amount } = Object.fromEntries(await request.formData())
    try {
        const res = await createPaymentLink(params.invoiceId, {
            amount,
            payment_method
        })
        message.success(res)
        return redirect('..', {
            relative: 'route'
        })
    }
    catch (error) {
        message.error(error.message)
    }
    return false
}

const PaymentLinkDetails = ({ data = {}, onClose }) => {
    const getAmount = () => {
        let amount = data.amount
        if (data.currency !== ERP_CURRENCY) {
            amount = convertFromERPCurrency(amount, data.conversion_rate?.[data.currency])
        }
        return formatPrice(amount, data.currency)
    }

    return (
        <Drawer open={true} onClose={onClose} width='480px' >
            <Descriptions column={1} bordered>
                <Descriptions.Item label='Amount' >{getAmount()}</Descriptions.Item>
                <Descriptions.Item label='Expiration Date' >{formatDate(data.expiration_date)}</Descriptions.Item>
                <Descriptions.Item label='Status' >{data.status_text}</Descriptions.Item>
                <Descriptions.Item label='Transaction Key' >{data.transaction_key}</Descriptions.Item>
                <Descriptions.Item label='Crearted At' >{formatDateTime(data.createdAt)}</Descriptions.Item>
                <Descriptions.Item label='Link'>{<a href={data.payment_link} target='_blank' rel="noreferrer" >Open</a>}</Descriptions.Item>
                <Descriptions.Item label='Payment Method'>{PaymentMethods[data.payment_method]}</Descriptions.Item>
            </Descriptions>
        </Drawer >
    )
}
PaymentLinkDetails.propTypes = {
    data: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
}

export { CreatePaymentLink, PaymentLinkDetails };

