import { FilterOutlined, ReloadOutlined } from '@ant-design/icons';
import {
    Button, Form, Input
} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useLoaderData } from 'react-router-dom';
import { convertDataTypes } from '../../../library/helpers/index.js';
import { CompanySelect, CountrySelect, LanguageSelect } from '../../inputs';
import { normalizeEmail } from '../../../library/helpers/normalize.js';

const ContactFilterForm = ({ children, data, ...otherProps }) => {
    const { meta } = useLoaderData()
    const [form] = Form.useForm()

    const clearForm = () => {
        try {
            form.setFieldsValue(Object.fromEntries(
                Object.keys(form.getFieldsValue()).map(key => ([key, null]))
            ))
            form.submit()
        }
        catch (e) {
            console.log(e);
        }
    }

    return (
        <Form form={form} initialValues={convertDataTypes(data, {
            customer_id: parseInt,
            country_id: parseInt,
            language_id: parseInt,
        })} layout='vertical'  {...otherProps}  >
            <Form.Item name="first_name" label="First Name">
                <Input />
            </Form.Item>
            <Form.Item name="last_name" label="Last Name">
                <Input />
            </Form.Item>
            <Form.Item name="customer_id" label="Customer">
                <CompanySelect currentSelection={meta?.filters?.customer_id?.id ? { value: meta.filters.customer_id.id, label: meta.filters.customer_id.name } : null} />
            </Form.Item>
            <Form.Item normalize={normalizeEmail} name="email" label="Email">
                <Input type="text" />
            </Form.Item>
            <Form.Item name="phone_number" label="Contact Number" >
                <Input />
            </Form.Item>
            <Form.Item name='country_id' label="Country">
                <CountrySelect allowClear />
            </Form.Item>
            <Form.Item name='language_id' label="Language">
                <LanguageSelect allowClear />
            </Form.Item>
            {children}
            <Form.Item label=" ">
                <Button htmlType="submit" type="primary" icon={<FilterOutlined />}>Apply</Button>
                <Button onClick={clearForm} style={{ marginLeft: 20 }} icon={<ReloadOutlined />}>Reset</Button>
            </Form.Item>
        </Form>
    )
}

ContactFilterForm.propTypes = {
    children: PropTypes.node,
    data: PropTypes.object,
    onFinish: PropTypes.func,
}

export default ContactFilterForm