import { Descriptions, Drawer } from 'antd';
import React from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { getEmailDetails } from '../../api/settings/email-template.js';

const EmailDetails = () => {
    const { email } = useLoaderData()
    const navigate = useNavigate()

    return (
        <Drawer size="large" open={true} mask={false} title="Email Details" onClose={() => navigate('..', { replace: true, relative: 'route' })} >
            <Descriptions column={1} bordered={true} layout="vertical">
                <Descriptions.Item label="Suibject">{email.subject}</Descriptions.Item>
                <Descriptions.Item label="Body"><div dangerouslySetInnerHTML={{ __html: email.message }}></div></Descriptions.Item>
            </Descriptions>
        </Drawer >
    )
}

EmailDetails.Loader = async ({ params }) => {
    const { id, emailId } = params
    const email = await getEmailDetails(id, emailId)
    return { email }
}

export default EmailDetails







