import { FilterOutlined, ReloadOutlined } from '@ant-design/icons';
import {
    Button,
    Form, Input, Select
} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useLoaderData } from 'react-router-dom';
import { ProjectStatuses } from '../../../library/constants/project-statuses.js';
import { convertDataTypes } from '../../../library/helpers/index.js';
import { normalizeEmail } from '../../../library/helpers/normalize.js';
import { AccountManagerSelect, CompanySelect, CountrySelect, ExecutiveEntitySelect, LanguageSelect, OrderManagerSelect } from '../../inputs';

const ProjectFilterForm = ({ children, data, ...otherProps }) => {
    const { meta } = useLoaderData()
    const [form] = Form.useForm()
    const { Option } = Select;

    const clearForm = () => {
        try {
            form.setFieldsValue(Object.fromEntries(
                Object.keys(form.getFieldsValue()).map(key => ([key, null]))
            ))
            form.submit()
        }
        catch (e) {
            console.log(e);
        }
    }

    return (
        <Form initialValues={convertDataTypes(data, {
            customer_id: parseInt,
            account_manager_id: parseInt,
            country_id: parseInt,
            language_id: parseInt,
            entity_id: parseInt,
            order_manager_id: parseInt,
            status: parseInt,
        })} form={form} layout='vertical' {...otherProps}>
            <Form.Item name="customer_id" label="Customer">
                <CompanySelect currentSelection={meta?.filters?.customer_id?.id ? { value: meta.filters.customer_id.id, label: meta.filters.customer_id.name } : null} />
            </Form.Item>
            <Form.Item normalize={normalizeEmail} name="email" label="Email">
                <Input type="text" />
            </Form.Item>
            <Form.Item name="account_manager_id" label="Account Manager">
                <AccountManagerSelect currentSelection={meta?.filters?.account_manager_id?.id ? { value: meta.filters.account_manager_id.id, label: meta.filters.account_manager_id.name } : null} />
            </Form.Item>
            <Form.Item name='country_id' label="Country">
                <CountrySelect allowClear={true} />
            </Form.Item>
            <Form.Item name='language_id' label="Language">
                <LanguageSelect allowClear={true} />
            </Form.Item>
            <Form.Item name='entity_id' label="Executive Entity">
                <ExecutiveEntitySelect allowClear />
            </Form.Item>
            <Form.Item name="order_manager_id" label="Order Manager">
                <OrderManagerSelect />
            </Form.Item>
            <Form.Item name="source" label="Source">
                <Select allowClear>
                    <Option value="email">Email</Option>
                    <Option value="website">Website</Option>
                    <Option value="phone">Phone</Option>
                </Select>
            </Form.Item>
            <Form.Item name="status" label="Status">
                <Select
                    options={[
                        { label: 'New Request', value: ProjectStatuses.NEW },
                        { label: 'Quotation Sent', value: ProjectStatuses.QUOTATION_SENT },
                        { label: 'Won - Confirmation client sent', value: ProjectStatuses.CONFIRMATION_CLIENT_SENT },
                        { label: 'Closed Lost', value: ProjectStatuses.CLOSED_LOST },
                    ]}
                    allowClear
                />
            </Form.Item>
            {/* <Form.Item name="createdAt" label="Status" >
                <DateRangePicker
                    ranges={{
                        Today: [moment(), moment()],
                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                    }}
                    allowEmpty={[true, true]}
                />
            </Form.Item> */}
            {children}
            <div className="actions">
                <Button htmlType="submit" type="primary" icon={<FilterOutlined />}>Apply</Button>
                <Button onClick={clearForm} icon={<ReloadOutlined />}>Reset</Button>
            </div>
        </Form>
    )
}

ProjectFilterForm.propTypes = {
    children: PropTypes.node,
    data: PropTypes.object,
}

export default ProjectFilterForm