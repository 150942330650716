import { FilterOutlined } from '@ant-design/icons';
import { Button, Drawer, Input, Space } from 'antd';
import { debounce } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { getSepaPaymentFiles } from '../../api/finance/sepa-payment.js';
import { SelectedFilters } from '../../components/data/selected-filters.jsx';
import SepaPaymentFilterForm from '../../components/form/sepaPayment-filter/sepaPayment-filter.jsx';
import { Page } from '../../components/page';
import { Table } from '../../components/table';
import { useFetch } from '../../hooks';
import { generateRouteUrl } from '../../library/constants';
import { SepaPaymentColumns } from '../../library/constants/tableColumns/SepaPaymentColumns.js';
import { searchParamsToObject } from '../../library/helpers/index.js';

const FILTER_NAMES = {
  entity_id: "Executive Entity",
  paid_by: "Paid By",
  status: "Status",
}

function SepaPaymentFiles({ title }) {
  const initialData = useLoaderData()
  const navigate = useNavigate()
  const [filtersVisible, setFiltersVisible] = useState(false)
  const [list, hasMore, isLoading, searchParamsParsed, {
    setSort,
    loadMore,
    setFilters
  }] = useFetch({ initialData })

  const handleRowClick = (_, record) => {
    navigate(generateRouteUrl('SepaPaymentDetails', {
      sepaPaymentId: record.id
    }))
  }

  const debouncedChangeHandler = debounce(q => setFilters({ search: q }), 500)

  return (
    <Page
      className="SepaPaymentFiles"
      title={title}
      summary={initialData?.meta?.totalCount ? `(${initialData?.meta?.totalCount} Records)` : '(No Records)'}
      header={
        <>
          <div></div>
          <Space.Compact style={{ width: '100%', maxWidth: '400px' }}>
            <Input.Search
              placeholder="Search"
              onChange={(e) => debouncedChangeHandler(e.target.value)}
              defaultValue={searchParamsParsed?.filter?.search ?? ""}
              allowClear
            />
            <Button
              onClick={() => setFiltersVisible((v) => !v)}
              icon={<FilterOutlined />}
            >
              Filters
            </Button>
          </Space.Compact>
          <div></div>
        </>
      }
      subHeader={initialData.meta?.filters && <SelectedFilters filters={initialData.meta.filters} filterNames={FILTER_NAMES} updateFilters={setFilters} />}
    >
      <Drawer open={filtersVisible} onClose={() => setFiltersVisible(false)} >
        <SepaPaymentFilterForm key={JSON.stringify(searchParamsParsed.filter)} data={searchParamsParsed.filter} onFinish={setFilters} />
      </Drawer>
      {!!list && (
        <>
          <Table
            columns={SepaPaymentColumns}
            data={list}
            isLoading={isLoading}
            onChange={setSort}
            onRowClick={handleRowClick}
            hasMore={hasMore}
            loadMore={loadMore}
          />
        </>
      )}
    </Page>
  );
}

SepaPaymentFiles.Loader = async ({ request }) => {
  const url = new URL(request.url)
  const { filter, page, sort } = searchParamsToObject(url.searchParams)
  const { data, meta } = await getSepaPaymentFiles({ filter, page, ...sort })
  return { data, meta }
}

SepaPaymentFiles.propTypes = {
  title: PropTypes.string,
}

export default SepaPaymentFiles
