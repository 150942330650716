import Langauge from '../dynamics/languages'
export const supportedLanguages = [
    'nl',
    'be',
    'be_fr',
    'fr',
    'lu_fr',
    'lu_de',
    'de',
    'en',
    'dk',
    'es',
    'ch',
    'at',
    'it',
]
export const getLanguageById = (language_id) => Langauge.find(({ id }) => id === language_id)
export const getLanguageIdByCode = (code) => Langauge.find(({ cms_language_code }) => cms_language_code === code)?.id

export const cmsLanguageName = lang => lang.toUpperCase().split('_').join(' / ')