import { CaretDownOutlined, FilterOutlined } from '@ant-design/icons';
import { Button, Drawer, Dropdown, Input, Space, message } from 'antd';
import { debounce } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { generatePath, redirect, useFetcher, useLoaderData, useNavigate } from 'react-router-dom';
import { generateSepaPaymentFile, getPurchaseBills } from '../../api/finance';
import { SelectedFilters } from '../../components/data/selected-filters.jsx';
import PurchaseBillFilterForm from '../../components/form/project/purchase-bill-filter-form.js';
import { Page } from '../../components/page';
import { Table } from '../../components/table';
import { useFetch } from '../../hooks';
import { useUpdateQueryStringValueWithoutNavigation } from '../../hooks/use-update-query-string-without-navigation.js';
import { PurchaseBillColumns, generateRouteUrl } from '../../library/constants';
import { PurchaseBillFilters } from '../../library/constants/purchase-bill-filters.js';
import { PurchaseBillStatuses } from '../../library/constants/purchase-bill-statuses.js';
import { convertDataTypes, searchParamsToObject } from '../../library/helpers/index.js';

const FILTER_NAMES = {
  vendor_id: "Supplier",
  entity_id: "Executive Entity",
  invoice_number: "Invoice No.",
  status: "Status",
}

function PurchaseBills({ title }) {
  const initialData = useLoaderData()
  const navigate = useNavigate()
  const [selectedRowsString, setSelectedRows] = useUpdateQueryStringValueWithoutNavigation('selectedRows')
  const selectedRows = !selectedRowsString ? [] : selectedRowsString.split(',').map(Number)
  const [isFiltersVisible, setIsFiltersVisible] = useState(false)
  const [list, hasMore, isLoading, searchParamsParsed, {
    setSort,
    setFilters,
    loadMore
  }] = useFetch({ initialData })
  const parsedFilters = convertDataTypes(searchParamsParsed?.filter, {
    entity_id: parseInt,
  })

  const fetcher = useFetcher()
  const debouncedChangeHandler = debounce(q => setFilters({ search: q }), 500)

  const handleRowClick = (_, record) => {
    navigate(generatePath('/finance/purchase-bills/:id', {
      id: record.id
    }))
  }

  return (
    <Page className='PurchaseBills' title={title} summary={initialData?.meta?.totalCount ? `(${initialData?.meta?.totalCount} Records)` : '(No Records)'} header={
      <>
        <div></div>
        <Space.Compact style={{ width: '100%', maxWidth: '400px' }}>
          <Input.Search placeholder="Search" onChange={(e) => debouncedChangeHandler(e.target.value)} defaultValue={searchParamsParsed?.filter?.search ?? ''} allowClear />
          <Dropdown.Button
            onClick={() => setIsFiltersVisible(v => !v)}
            icon={<CaretDownOutlined />}
            menu={{
              items: PurchaseBillFilters,
              selectable: true,
              onClick: ({ keyPath }) => setFilters(PurchaseBillFilters.findFiltersByKey(keyPath))
              // defaultSelectedKeys: []
            }}
          >
            <Space>Custom Filters < FilterOutlined /></Space>
          </Dropdown.Button>
        </Space.Compact>
        <fetcher.Form method="post">
          <input type="hidden" name="purchaseBillIds" value={selectedRowsString} />
          <Button name="action" value="generateSepaFile" htmlType="submit">Pay (Generate Sepa File)</Button>
        </fetcher.Form>
      </>
    } subHeader={initialData.meta?.filters && <SelectedFilters filters={initialData.meta.filters} filterNames={FILTER_NAMES} updateFilters={setFilters} />} backLink={false} >
      {!!list && (
        <>
          <Table
            columns={PurchaseBillColumns}
            data={list}
            isLoading={isLoading}
            onChange={setSort}
            onRowClick={handleRowClick}
            hasMore={hasMore}
            loadMore={loadMore}
            rowSelection={{
              type: "checkbox",
              columnWidth: 50,
              fixed: true,
              selectedRowKeys: selectedRows,
              getCheckboxProps: (record) => ({
                disabled: record.status !== PurchaseBillStatuses.APPROVED,
                onClick: (e) => e.stopPropagation()
                // Column configuration not to be checked
                // name: record.name,
              }),
              onChange: (selectedRowKeys) => {
                setSelectedRows(selectedRowKeys.join(','))
              },
            }}
          />
        </>
      )}
      <Drawer open={isFiltersVisible} onClose={() => setIsFiltersVisible(false)} >
        <PurchaseBillFilterForm key={JSON.stringify(searchParamsParsed.filter)} data={parsedFilters} onFinish={(data) => setFilters(data)} />
      </Drawer>
    </Page>
  )
}

PurchaseBills.Actions = {
  generateSepaFile: async ({ data }) => {
    try {
      const purchaseBillIds = data.purchaseBillIds.split(',').map(id => parseInt(id)).filter(id => !isNaN(id))
      if (purchaseBillIds.length === 0) {
        throw new Error('Select atleast one purchase bill')
      }
      const { message: msg, data: sepaPaymentId } = await generateSepaPaymentFile(purchaseBillIds)
      message.success(msg)
      return redirect(generateRouteUrl('SepaPaymentDetails', {
        sepaPaymentId
      }))
    }
    catch (error) {
      message.error(error.message)
    }
    return false
  }
}
PurchaseBills.Loader = async ({ request }) => {
  const url = new URL(request.url)
  const { filter, page, sort } = searchParamsToObject(url.searchParams)
  const { data, meta } = await getPurchaseBills({ filter, page, ...sort })
  return { data, meta }
}

PurchaseBills.propTypes = {
  title: PropTypes.string,
}

export default PurchaseBills
