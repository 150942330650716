import { FilterOutlined, ReloadOutlined } from '@ant-design/icons';
import {
    Button,
    Form,
    Select
} from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useLoaderData } from 'react-router-dom';
import { getOrderManagerSteps } from '../../../library/constants/steps/order-manager-steps.js';
import { convertDataTypes } from '../../../library/helpers/index.js';
import { AccountManagerSelect, CompanySelect, CountrySelect, ExecutiveEntitySelect, LanguageSelect, OrderManagerMultiSelect } from '../../inputs';
import ProductSearch from '../../inputs/product-search.jsx';

const OrderFilterForm = ({ children, data, ...otherProps }) => {
    const { meta } = useLoaderData()
    const [form] = Form.useForm()
    const parsedData = useMemo(() => (convertDataTypes(data, {
        order_manager_id: (ids) => {
            const parsedIds = ids?.split(',').map(id => parseInt(id, 10)).filter(id => !isNaN(id));
            return parsedIds?.length ? parsedIds : null;
        },
        account_manager_id: parseInt,
        customer_id: parseInt,
        country_id: parseInt,
        language_id: parseInt,
        entity_id: parseInt,
        order_status: parseInt,
        product_id: parseInt,
    })), [data])

    const clearForm = () => {
        try {
            form.setFieldsValue(Object.fromEntries(
                Object.keys(form.getFieldsValue()).map(key => ([key, null]))
            ))
            form.submit()
        }
        catch (e) {
            console.log(e);
        }
    }

    return (
        <Form initialValues={parsedData} form={form} layout='vertical' {...otherProps}>
            <Form.Item name="order_manager_id" label="Order Manager">
                <OrderManagerMultiSelect />
            </Form.Item>
            <Form.Item name="account_manager_id" label="Account Manager">
                <AccountManagerSelect currentSelection={meta?.filters?.account_manager_id?.id ? { value: meta.filters.account_manager_id.id, label: meta.filters.account_manager_id.name } : null} />
            </Form.Item>
            <Form.Item name="customer_id" label="Customer">
                <CompanySelect currentSelection={meta?.filters?.customer_id?.id ? { value: meta.filters.customer_id.id, label: meta.filters.customer_id.name } : null} />
            </Form.Item>
            <Form.Item name='country_id' label="Country">
                <CountrySelect allowClear={true} />
            </Form.Item>
            <Form.Item name='language_id' label="Language">
                <LanguageSelect allowClear={true} />
            </Form.Item>
            <Form.Item name='entity_id' label="Executive Entity">
                <ExecutiveEntitySelect allowClear />
            </Form.Item>
            <Form.Item name="order_status" label="Status" >
                <Select
                    options={getOrderManagerSteps()}
                    fieldNames={{
                        label: 'title',
                        value: 'status',
                    }}
                    allowClear
                />
            </Form.Item>
            <Form.Item name="product_id" label="Product" >
                <ProductSearch language="en" width='100%' />
            </Form.Item>
            {children}
            <div className="actions">
                <Button htmlType="submit" type="primary" icon={<FilterOutlined />}>Apply</Button>
                <Button onClick={clearForm} icon={<ReloadOutlined />}>Reset</Button>
            </div>
        </Form>
    )
}

OrderFilterForm.propTypes = {
    children: PropTypes.node,
    data: PropTypes.object,
}

export default OrderFilterForm