import { Input } from 'antd';
import PropTypes from 'prop-types';
import { React, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ValidationForm as Form } from '../';
import { getCustomerDetails } from '../../../api/customer/customer-details.js';
import { generateRouteUrl } from '../../../library/constants/index.js';
import { getCountryById, getEntityById } from '../../../library/helpers';
import { CompanySelect, GenderSelect, GeneralFieldWrapper, LanguageSelect, PhoneInput, RoleSelect } from '../../inputs';
import PhoneDisplay from '../../inputs/PhoneDisplay.jsx';
import styles from './contact.module.scss';
import { normalizeEmail } from '../../../library/helpers/normalize.js';

const ContactDisabled = ({ data = {} }) => (
	<div className="form-fields-wrapper form-fields-wrapper" style={{ alignItems: 'start' }}>
		<GeneralFieldWrapper label="First Name">{data.first_name}</GeneralFieldWrapper>
		<GeneralFieldWrapper label="Last Name">{data.last_name}</GeneralFieldWrapper>
		<GeneralFieldWrapper label="Company"><Link to={generateRouteUrl('CustomerDetails', { id: data.customer_id })}>{data?.customer?.company}</Link></GeneralFieldWrapper>
		<GeneralFieldWrapper label="Email Address">{data.email}</GeneralFieldWrapper>
		<GeneralFieldWrapper label="Role in Company">{data.role?.name}</GeneralFieldWrapper>
		<GeneralFieldWrapper label="Phone Number"><PhoneDisplay code={data.phone_country_code} number={data.phone_number} /></GeneralFieldWrapper>
		<GeneralFieldWrapper label="Mobile Number"><PhoneDisplay code={data.mobile_country_code} number={data.mobile_number} /></GeneralFieldWrapper>
		<GeneralFieldWrapper label="Gender">{data.gender?.name}</GeneralFieldWrapper>
		<GeneralFieldWrapper label="Language">{data.language?.name}</GeneralFieldWrapper>
	</div>
)

ContactDisabled.propTypes = {
	data: PropTypes.object.isRequired
}


const Contact = ({ children, isUpdate, ...otherProps }) => {
	const [allowedLanguages, setAllowedLanguages] = useState([])
	const [form] = Form.useForm()
	const validationRules = {
		first_name: [
			{
				required: true,
				message: 'First Name is required'
			}
		],
		last_name: [
			{
				required: true,
				message: 'Last Name is required'
			}
		],
		customer_id: [
			{
				required: true,
				message: 'Customer is required'
			}
		],
		email: [
			{
				required: true,
				message: 'Email is required'
			},
			{
				type: 'email',
				message: 'Email is not valid'
			}
		],
		company_role: [
			{
				required: true,
				message: 'Role is required'
			}
		],
		gender_id: [
			{
				required: true,
				message: 'Gender is required'
			}
		],
		language_id: [
			{
				required: true,
				message: 'Language is required'
			}
		],
	}

	useEffect(() => {
		if (otherProps.initialValues?.customer_id) {
			handleCustomerChange(otherProps.initialValues.customer_id)
		}
	}, [otherProps.initialValues?.customer_id])

	const handleCustomerChange = async (customerId) => {
		const customer = await getCustomerDetails(customerId)
		const updatedFields = {}
		if (customer.country_id) {
			const { phone_number_code } = getCountryById(customer.country_id) || {}
			updatedFields.phone_country_code = phone_number_code
			updatedFields.mobile_country_code = phone_number_code
		}
		if (customer.entity_id) {
			const { default_language = [] } = getEntityById(customer.entity_id) || {}
			setAllowedLanguages(default_language)
			if (default_language?.length === 1) {
				updatedFields.language_id = default_language[0]
			}
		}
		if (Object.keys(updatedFields)?.length > 0) {
			form.setFieldsValue(updatedFields)
		}
	}

	return (
		<Form form={form} name="contact" layout="vertical" validationRules={validationRules} className={styles.FormWrapper} {...otherProps}>
			<Form.Item name="first_name" label="First Name" >
				<Input />
			</Form.Item>
			<Form.Item name="last_name" label="Last Name" >
				<Input />
			</Form.Item>
			{isUpdate ? (
				<Form.Item label="Company" >
					<Link to={generateRouteUrl('CustomerDetails', { id: otherProps.initialValues.customer_id })}>{otherProps.initialValues?.customer?.company}</Link>
				</Form.Item>
			) : (
				<Form.Item name='customer_id' label="Company" >
					<CompanySelect
						currentSelection={{ value: otherProps?.initialValues?.customer_id, label: otherProps?.initialValues?.customer?.company }}
						onChange={handleCustomerChange}
					/>
				</Form.Item>
			)}
			<Form.Item normalize={normalizeEmail} name="email" label="Email" >
				<Input type="email" />
			</Form.Item>
			<Form.Item name='company_role' label="Role (Department)" >
				<RoleSelect />
			</Form.Item>
			<Form.Item label="Phone Number" required>
				<PhoneInput valuePropName={{ code: 'phone_country_code', number: 'phone_number' }} label='Phone No.' required />
			</Form.Item>
			<Form.Item label="Mobile Number">
				<PhoneInput valuePropName={{ code: 'mobile_country_code', number: 'mobile_number' }} label='Mobile No.' />
			</Form.Item>
			<Form.Item name='gender_id' label="Gender" >
				<GenderSelect />
			</Form.Item>
			<Form.Item name='language_id' label="Language" >
				<LanguageSelect options={allowedLanguages?.map(lang => parseInt(lang))} />
			</Form.Item>
			{children}
		</Form>
	)
}

Contact.propTypes = {
	children: PropTypes.node,
	// initialValues: PropTypes.object,
	isUpdate: PropTypes.bool,
}

Contact.Disabled = ContactDisabled

export default Contact