import {
	Input
} from 'antd';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';
import { ValidationForm as Form } from '../';
import { normalizeAlphaNumeric } from '../../../library/helpers/normalize';
import { DatePicker } from '../../inputs';
import ProductSelect from '../../inputs/ProductSelect.jsx';
import styles from './project-item.module.scss';

const ProjectItem = ({ children, language, getValidationStatus, handleProductChange, product, isUpdate, canChangeProduct, ...otherProps }) => {

	const [form] = Form.useForm()

	const validationRules = {
		product_id: [
			{
				required: true,
				type: 'number'
			}
		]
	}

	return (
		<Form form={form} name='projectItemDetails' onValidationStatusChange={getValidationStatus ?? undefined} layout="vertical" className={styles.FormWrapper} validationRules={validationRules} {...otherProps}>
			<Form.Item name="product_id" label="Product" className={styles['FormField--FullCol']} getValueFromEvent={({ id }) => id}>
				<ProductSelect language={language?.cms_language_code} currentSelection={product ?? null} onChange={handleProductChange ?? undefined} disabled={isUpdate && !canChangeProduct} allowClear={false} />
			</Form.Item>

			<Form.Item name="expected_delivery_period" label="Expected Production/Delivery Period" normalize={normalizeAlphaNumeric} >
				{otherProps?.initialValues?.expected_delivery_period ?
					<Input disabled={false} /> : (
						<span style={{ color: '#333', fontStyle: 'italic' }}>Not Available</span>
					)}
			</Form.Item>
			<Form.Item name="client_expected_delivery_date" label="Client's Expected Delivery Date">
				<DatePicker disabledBefore={dayjs().add(1, 'day').format('YYYY-MM-DD')} />
			</Form.Item>
			{children}
		</Form>
	)
}

ProjectItem.propTypes = {
	children: PropTypes.node,
	language: PropTypes.shape({
		cms_language_code: PropTypes.string.isRequired
	}).isRequired,
	onSubmit: PropTypes.func,
	getValidationStatus: PropTypes.func,
	handleProductChange: PropTypes.func,
	product: PropTypes.object,
	canChangeProduct: PropTypes.bool,
	isUpdate: PropTypes.bool,
}

export default ProjectItem