import { AppstoreOutlined, BarsOutlined, FilterOutlined, GlobalOutlined, MailOutlined, MobileOutlined } from '@ant-design/icons';
import { Button, Drawer, Input, Segmented, Space, Spin, Switch } from 'antd';
import { debounce } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { generatePath, useLoaderData, useNavigate, useRouteLoaderData, useSearchParams } from 'react-router-dom';
import { getAllOrders, getOrdersKanabanCount } from '../../api/order/order-manager';
import { SelectedFilters } from '../../components/data/selected-filters.jsx';
import { fetchSmallWebsiteLogo } from '../../components/design';
import KanabanBoard from '../../components/design/kanaban';
import OrderFilterForm from '../../components/form/order-manager/order-filter.jsx';
import styles from '../../components/form/project/project-details.module.scss';
import { Page } from '../../components/page';
import { Table } from '../../components/table';
import { useFetch } from '../../hooks';
import { getUserSettings, Server, setUserSettings } from '../../library/constants';
import { ERP_CURRENCY } from '../../library/constants/dynamic.js';
import { OrderKanabanColumn } from '../../library/constants/kanabanColumns/orderKanabanColumn';
import { OrderColumns } from '../../library/constants/tableColumns/index';
import { getFormattedId, searchParamsToObject } from '../../library/helpers';
import { formatDate, formatPrice } from '../../library/utilities/intl';
const VIEW_MODES = Object.freeze({
  LIST: "list",
  KANABAN: "kanaban",
})

const FILTER_NAMES = {
  order_manager_id: 'Order Manager',
  account_manager_id: 'Account Manager',
  customer_id: 'Customer',
  country_id: 'Country',
  language_id: 'Language',
  entity_id: 'Executive Entity',
  order_status: 'Status',
  product_id: 'Product',
}

const getSourceIcon = (source) => {
  switch (source) {
    case 'email':
      return <MailOutlined className={styles.mail} />
    case 'website':
      return <GlobalOutlined className={styles.globe} />
    case 'phone':
      return <MobileOutlined className={styles.phone} />
  }
}
const renderOrderKanabanCard = ({ item, onClick }) => {
  return (
    <div className='kanaban__card' onClick={() => onClick(item)}>
      <div className="kanaban__card__header">
        {!!item.deadline_delivery_text && (
          <span
            className={`priority ${item.deadline_delivery_text === "upcoming" ? "upcoming" :
                item.deadline_delivery_text === "passed" ? "delayed" : ""
              }`}
          >
            {item.deadline_delivery_text === "upcoming"
              ? "UPCOMING DEADLINE"
              : item.deadline_delivery_text === "passed"
                ? "DELAYED ITEMS"
                : ""}
          </span>
        )}
      </div>
      <div className="kanaban__card__header">
        {!!item.client_expected_delivery_date && (
          <p className='priority'>Deadline Delivery :  {formatDate((item.client_expected_delivery_date))}</p>
        )}
      </div>
      <div className="kanaban__card__body">
        <div className="source">
          <div className="numberIcon">
            <span className="project-number">{getFormattedId(item.id)}</span>
            <span className="project-source">{getSourceIcon(item.project.source?.id)}</span>
          </div>
          <span className="project-country">
            <img src={`${Server.BaseUrl}/${item.project.customer.country.flag_url}`} alt={item.project.customer.country.name} title={item.project.customer.country.name} width={32} />
          </span>
        </div>
        <span className="customer-logo">
          {fetchSmallWebsiteLogo(item.project.customer.website, item.project.customer.company)}
        </span>
        <div className='itemName'>
          {item.product.name}
        </div>
        <div className="details">
          <p className='head'>Account Mananger</p>
          <p className='leftHead'>Total Price</p>
          <p className='value'>{item.project.user?.name}</p>
          <p className='leftValue'>{formatPrice(item.approved_vendor_price?.total_price, ERP_CURRENCY)}</p>
        </div>
      </div>
      <div className="kanaban__card__footer">
        {/* <span className='payment'>Payment Status  : <span className='payment_value'> {item.project.payment_term?.id}</span></span> */}
        <span className='payment'>Order Manager  : <span className='payment_value'> {item.order_manager?.name}</span></span>
      </div>
    </div>

  );
};

const OrderListView = ({ data, isLoading = false, hasMore, onChangeSort, onLoadMore, onItemClick }) => {
  return (
    <Table
      columns={OrderColumns}
      data={data}
      isLoading={isLoading}
      onChange={onChangeSort}
      onRowClick={(_, record) => onItemClick(record)}
      hasMore={hasMore}
      loadMore={onLoadMore}
    />
  )
}
OrderListView.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool,
  hasMore: PropTypes.bool.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  onItemClick: PropTypes.func.isRequired
}

const OrderItemKanabanView = ({ data, isLoading = false, hasMore, onLoadMore, onItemClick, kanabanCount }) => {
  const columns = OrderKanabanColumn.map(column => ({
    ...column,
    items: data.filter(({ order_status }) => order_status === column.key) ?? [],
    count: kanabanCount?.find(({ order_status }) => order_status === column.key)?.count
  }))

  if (isLoading) {
    return (
      <>
        <Spin />
      </>
    )
  }

  return (
    <KanabanBoard
      columns={columns}
      renderItem={renderOrderKanabanCard}
      hasMore={hasMore}
      onItemClick={onItemClick}
      loadMore={onLoadMore}
    />
  )
}
OrderItemKanabanView.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool,
  hasMore: PropTypes.bool.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  onItemClick: PropTypes.func.isRequired,
  kanabanCount: PropTypes.arrayOf(PropTypes.object).isRequired,
}

function Order(props) {
  const [searchParams, setSearchParams] = useSearchParams()
  const initialData = useLoaderData()
  const [isFiltersVisible, setIsFiltersVisible] = useState(false)
  const { user } = useRouteLoaderData('Root')
  const navigate = useNavigate()
  //const [filtersVisible, setFiltersVisible] = useState(false)
  const [list, hasMore, isLoading, searchParamsParsed, {
    setSort,
    setFilters,
    loadMore
  }] = useFetch({ initialData })

  const debouncedChangeHandler = debounce(q => setFilters({
    ...(searchParamsParsed?.filter ?? {}),
    search: q
  }), 500)

  const viewMode = searchParams.get('view') ?? getUserSettings('orderManagerListView') ?? VIEW_MODES.KANABAN
  const setViewMode = (mode) => setSearchParams(sp => {
    sp.set('view', mode)
    return sp
  })

  const handleRowClick = (record) => {
    navigate(generatePath('/order-manager/:id', {
      id: record.id
    }))
  }

  return (
    <Page className='order-list' backLink={false} title={props.title} summary={initialData?.meta?.totalCount ? `(${initialData?.meta?.totalCount} Records)` : '(No Records)'} header={
      <>
        <Segmented
          value={viewMode}
          options={[
            {
              value: VIEW_MODES.LIST,
              icon: <BarsOutlined />,
            },
            {
              value: VIEW_MODES.KANABAN,
              icon: <AppstoreOutlined />,
            },
          ]}
          onChange={setViewMode}
        />
        <Space.Compact style={{ width: '100%', maxWidth: '400px' }}>
          <Input.Search placeholder="Search" onChange={(e) => {
            if (e.target.value === '') {
              setSearchParams(sp => {
                sp.delete('filter[search]')
                return sp
              })
              return;
            }
            return debouncedChangeHandler(e.target.value)
          }} defaultValue={searchParamsParsed?.filter?.search ?? ''} allowClear />
          <Button onClick={() => setIsFiltersVisible(v => !v)} icon={<FilterOutlined />}>Filters</Button>
        </Space.Compact>
        <Switch
          checkedChildren="Show All"
          unCheckedChildren="Show Assigned To Me"
          onChange={(checked) => {
            const _filters = { ...searchParamsParsed?.filter ?? {} }
            if (checked) {
              _filters.order_manager_id = user?.id
            }
            else {
              delete _filters.order_manager_id
            }
            setFilters(_filters)
          }}
          checked={searchParamsParsed?.filter?.order_manager_id}
        />
      </>
    } subHeader={initialData.meta?.filters && <SelectedFilters filters={initialData.meta.filters} filterNames={FILTER_NAMES} updateFilters={setFilters} />}>
      {/*  <Drawer open={filtersVisible} onClose={() => setFiltersVisible(false)} >
        <CustomerFilterForm data={filters} onFinish={(data) => setFilters(data)} />
      </Drawer> */}
      {viewMode === VIEW_MODES.LIST
        ? <OrderListView data={list} isLoading={isLoading} hasMore={hasMore} onChangeSort={setSort} onLoadMore={loadMore} onItemClick={handleRowClick} />
        : <OrderItemKanabanView data={list} kanabanCount={initialData.kanabanCount} isLoading={isLoading} hasMore={hasMore} onLoadMore={loadMore} onItemClick={handleRowClick} />
      }
      <Drawer open={isFiltersVisible} onClose={() => setIsFiltersVisible(false)} >
        <OrderFilterForm key={JSON.stringify(searchParamsParsed.filter)} data={searchParamsParsed.filter} onFinish={(data) => setFilters(data)} />
      </Drawer>
    </Page>
  )
}
Order.Loader = async ({ request }) => {
  const url = new URL(request.url)
  const { filter, page, sort, view = (getUserSettings('orderManagerListView') ?? VIEW_MODES.KANABAN) } = searchParamsToObject(url.searchParams)
  setUserSettings('orderManagerListView', view)
  const limit = view === VIEW_MODES.KANABAN ? 50 : 10
  const { data, meta } = await getAllOrders({ filter, page, limit, ...sort })

  //Loads kanaban count only in list view mode and on first request
  let kanabanCount = null
  if (view === VIEW_MODES.KANABAN && !page) {
    kanabanCount = await getOrdersKanabanCount({ filter })
  }

  return { data, meta, kanabanCount }

}



Order.propTypes = {
  title: PropTypes.string
}

export default Order
