import { FilterOutlined, ReloadOutlined } from '@ant-design/icons';
import {
    Button, Form, Input
} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useLoaderData } from 'react-router-dom';
import { convertDataTypes } from '../../../library/helpers/index.js';
import { CompanySelect, CountrySelect, ExecutiveEntitySelect, IndustrySelect, LanguageSelect } from '../../inputs';
import styles from './customer-filter.module.scss';
import { normalizeEmail } from '../../../library/helpers/normalize.js';

const CustomerFilterForm = ({ children, data, ...otherProps }) => {
    const { meta } = useLoaderData()

    const [form] = Form.useForm()

    const clearForm = () => {
        try {
            form.setFieldsValue(Object.fromEntries(
                Object.keys(form.getFieldsValue()).map(key => ([key, null]))
            ))
            form.submit()
        }
        catch (e) {
            console.log(e);
        }
    }

    return (
        <Form form={form} initialValues={convertDataTypes(data, {
            customer_id: parseInt,
            country_id: parseInt,
            language_id: parseInt,
            entity_id: parseInt,
            industry_id: parseInt,
        })} layout='vertical' {...otherProps} className={styles.FormWrapper} >
            <Form.Item name="customer_id" label="Customer">
                <CompanySelect currentSelection={meta?.filters?.customer_id?.id ? { value: meta.filters.customer_id.id, label: meta.filters.customer_id.name } : null} getPopupContainer={triggerNode => triggerNode.parentElement}/>
            </Form.Item>
            <Form.Item normalize={normalizeEmail} name="email" label="Email">
                <Input type="text" allowClear />
            </Form.Item>
            <Form.Item name='industry_id' label="Industry">
                <IndustrySelect allowClear getPopupContainer={triggerNode => triggerNode.parentElement}/>
            </Form.Item>
            <Form.Item name='country_id' label="Country">
                <CountrySelect allowClear getPopupContainer={triggerNode => triggerNode.parentElement}/>
            </Form.Item>
            <Form.Item name='language_id' label="Language">
                <LanguageSelect allowClear getPopupContainer={triggerNode => triggerNode.parentElement}/>
            </Form.Item>
            <Form.Item name='entity_id' label="Executive Entity">
                <ExecutiveEntitySelect allowClear getPopupContainer={triggerNode => triggerNode.parentElement}/>
            </Form.Item>
            <Form.Item name="website" label="Website">
                <Input type="text" allowClear />
            </Form.Item>
            {children}
            <div className="actions">
                <Button htmlType="submit" type="primary" icon={<FilterOutlined />}>Apply</Button>
                <Button onClick={clearForm} icon={<ReloadOutlined />}>Reset</Button>
            </div>
        </Form>
    )
}

CustomerFilterForm.propTypes = {
    children: PropTypes.node,
    data: PropTypes.object,
}

export default CustomerFilterForm