import {
    FormOutlined
} from '@ant-design/icons';
import { Button, Collapse, DatePicker, Input, Select } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Form, useFetcher } from 'react-router-dom';
import { formats, generateRouteUrl } from '../../../library/constants';
import Genders from '../../../library/dynamics/genders';
import { getFieldErrors, getFieldStatus } from '../../../library/helpers/forms/index.js';
import { formatDate } from '../../../library/utilities/intl.js';
import { SmallSection } from '../../design';
import { CountrySelect } from '../../inputs';
import ContactSelect from '../../inputs/ContactSelect.jsx';
import SelectableTextTemplate from '../../inputs/selectable-text-template.jsx';
import Styles from './invoice-basic-details.module.scss';
const { Panel } = Collapse
moment.locale('nl')

const InvoiceBasicDetails = ({ invoiceId, data }) => {
    const [isEditable, setIsEditable] = useState(false)
    const fetcher = useFetcher()
    useEffect(() => {
        if (fetcher.data === true) {
            setIsEditable(false)
        }
    }, [fetcher])

    return (
        <Collapse defaultActiveKey={['1']} expandIconPosition="end">
            <Panel header='Invoice Details' key="1" extra={
                !isEditable && <Button onClick={(event) => {
                    event.stopPropagation()
                    setIsEditable(true)
                }} icon={<FormOutlined />} type='actionpanel' shape='square' danger ghost />
            }>
                {isEditable ? (
                    <UpdateInvoiceBasicDetails
                        data={data}
                        errors={fetcher.data?.errors}
                        onSave={(form) => {
                            fetcher.submit(form, {
                                method: 'post',
                                action: generateRouteUrl('InvoiceDetails', { invoiceId })
                            })
                        }}
                        onCancel={() => setIsEditable(false)}
                    />
                ) : (
                    <InvoiceBasicDetailsView data={data} />
                )}
            </Panel>
        </Collapse >
    )
}
InvoiceBasicDetails.propTypes = {
    invoiceId: PropTypes.number.isRequired,
    data: PropTypes.object.isRequired,
}

const InvoiceBasicDetailsView = ({ data }) => {
    return (
        <div className={Styles.mainWrapper}>
            <SmallSection style={SmallSection.Styles.FINANCE} containerStyles={{ marginTop: 0 }}>
                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.description}>
                        <div className={Styles.DescriptionTitle}>Invoice No.</div>
                        <div className={Styles.DescriptionValue}>{data.invoice_number}</div>
                    </div>
                    <div className={Styles.description}>
                        <div className={Styles.DescriptionTitle}>Entity</div>
                        <div className={Styles.DescriptionValue}>{data?.entity?.name}</div>
                    </div>
                    <div className={Styles.description}>
                        <div className={Styles.DescriptionTitle}>Currency</div>
                        <div className={Styles.DescriptionValue}>{data?.currency?.name}</div>
                    </div>
                </div>
            </SmallSection>
            <SmallSection style={SmallSection.Styles.FINANCE}>
                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.description}>
                        <div className={Styles.DescriptionTitle}>Company Name</div>
                        <div className={Styles.DescriptionValue}>{data?.customer?.company}</div>
                    </div>
                    <div className={Styles.description}>
                        <div className={Styles.DescriptionTitle}>First Name</div>
                        <div className={Styles.DescriptionValue}>{data?.customer?.first_name}</div>
                    </div>
                    <div className={Styles.description}>
                        <div className={Styles.DescriptionTitle}>Last Name</div>
                        <div className={Styles.DescriptionValue}>{data?.customer?.last_name}</div>
                    </div>
                    <div className={Styles.description}>
                        <div className={Styles.DescriptionTitle}>VAT No.</div>
                        <div className={Styles.DescriptionValue}>{data?.customer?.vat_number}</div>
                    </div>
                    <div className={Styles.description}>
                        <div className={Styles.DescriptionTitle}>Email</div>
                        <div className={Styles.DescriptionValue}>{data?.customer?.email}</div>
                    </div>
                    <div className={Styles.description}>
                        <div className={Styles.DescriptionTitle}>Gender</div>
                        <div className={Styles.DescriptionValue}>{data?.customer?.gender?.name}</div>
                    </div>
                </div>
            </SmallSection>
            <SmallSection style={SmallSection.Styles.FINANCE}>
                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.description}>
                        <div className={Styles.DescriptionTitle}>Street</div>
                        <div className={Styles.DescriptionValue}>{data?.customer?.street}</div>
                    </div>
                    <div className={Styles.description}>
                        <div className={Styles.DescriptionTitle}>Street No.</div>
                        <div className={Styles.DescriptionValue}>{data?.customer?.street_number}</div>
                    </div>
                    <div className={Styles.description}>
                        <div className={Styles.DescriptionTitle}>Zip Code</div>
                        <div className={Styles.DescriptionValue}>{data?.customer?.zipcode}</div>
                    </div>
                    <div className={Styles.description}>
                        <div className={Styles.DescriptionTitle}>City</div>
                        <div className={Styles.DescriptionValue}>{data?.customer?.city}</div>
                    </div>
                    <div className={Styles.description}>
                        <div className={Styles.DescriptionTitle}>County</div>
                        <div className={Styles.DescriptionValue}>{data?.customer?.county}</div>
                    </div>
                    <div className={Styles.description}>
                        <div className={Styles.DescriptionTitle}>Country</div>
                        <div className={Styles.DescriptionValue}>{data?.customer?.country?.name}</div>
                    </div>
                    <div className={Styles.description}>
                        <div className={Styles.DescriptionTitle}>Address Rule 1</div>
                        <div className={Styles.DescriptionValue}>{data?.customer?.address_rule_1}</div>
                    </div>
                    <div className={Styles.description}>
                        <div className={Styles.DescriptionTitle}>Address Rule 2</div>
                        <div className={Styles.DescriptionValue}>{data?.customer?.address_rule_2}</div>
                    </div>
                </div>
            </SmallSection>
            <SmallSection style={SmallSection.Styles.FINANCE}>
                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.description}>
                        <div className={Styles.DescriptionTitle}>Invoice Date</div>
                        <div className={Styles.DescriptionValue}>{formatDate(data.invoice_date)}</div>
                    </div>
                    <div className={Styles.description}>
                        <div className={Styles.DescriptionTitle}>Expiry Date</div>
                        <div className={Styles.DescriptionValue}>{formatDate(data.expiry_date)}</div>
                    </div>
                    <div className={Styles.description}>
                        <div className={Styles.DescriptionTitle}>Lieferdatum</div>
                        <div className={Styles.DescriptionValue}>{data?.leiferdatum}</div>
                    </div>
                </div>
            </SmallSection>
            <SmallSection style={SmallSection.Styles.FINANCE}>
                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.description}>
                        <div className={Styles.DescriptionTitle}>Client Order No.</div>
                        <div className={Styles.DescriptionValue}>{data.client_order_number}</div>
                    </div>
                    <div className={Styles['description--span2']}>
                        <div className={Styles.DescriptionTitle}>Invoice Secondary Contact</div>
                        <div className={Styles.DescriptionValue}>{data.second_contact?.email}</div>
                    </div>
                    <div className={Styles['description--span3']}>
                        <div className={Styles.DescriptionTitle}>Footer Text</div>
                        <div className={Styles.DescriptionValue}>{data.footer}</div>
                    </div>
                </div>
            </SmallSection>
            <SmallSection style={SmallSection.Styles.FINANCE}>
                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.description}>
                        <div className={Styles.DescriptionTitle}>Bad Payment History</div>
                        <div className={Styles.DescriptionValue}>{data.customer.bad_payment_history === 1 ? 'Yes' : 'No'}</div>
                    </div>
                    <div className={Styles['description--span2']}>
                        <div className={Styles.DescriptionTitle}>Payment Notes</div>
                        <div className={Styles.DescriptionValue}>{data.customer.payment_notes}</div>
                    </div>
                </div>
            </SmallSection>
        </div>
    )
}
InvoiceBasicDetailsView.propTypes = {
    data: PropTypes.object.isRequired,
}

const UpdateInvoiceBasicDetails = ({ data, errors, onSave, onCancel }) => {
    // const [showContactSelect, setShowContactSelect] = useState(false)
    const [gender, setGender] = useState(data.customer.gender_id)
    const [secondContact, setSecondContact] = useState(data.second_contact)
    const [invoiceDate, setInvoiceDate] = useState(data.invoice_date)
    const [expiryDate, setExpiryDate] = useState(data.expiry_date)
    const [country, setCountry] = useState(Number(data.customer.country_id))
    const [selectedFooterTemplated, setSelectedFooterTemplate] = useState({})
    const [showFooterSelect, setShowFooterSelect] = useState(false)
    return (
        <>
            <Form method="post" onSubmit={(e) => {
                e.preventDefault()
                onSave(e.currentTarget)
            }} className={Styles.formWrapper} >
                <input type="hidden" name="action" value="updateBasicDetails" />
                <div className={Styles.mainWrapper}>
                    <div className={Styles.fieldsWrapper}>
                        <div className={Styles.inputField}>
                            <label>Invoice No.</label>
                            <Input value={data.invoice_number} disabled />
                        </div>
                        <div className={Styles.inputField} id="entitySelect">
                            <label>Entity</label>
                            {data.entity?.name}
                        </div>
                        <div className={Styles.inputField}>
                            <label>Currency</label>
                            {data.currency?.name}
                        </div>
                    </div>
                </div>
                <div className={Styles.mainWrapper}>
                    <div className={Styles.fieldsWrapper}>
                        <div className={Styles.inputField}>
                            <label>Company Name</label>
                            <Input name="company" defaultValue={data.customer.company} status={getFieldStatus('company', errors)} />
                            {getFieldErrors('company', errors)}

                        </div>
                        <div className={Styles.inputField}>
                            <label>Gender</label>
                            <Select options={Genders} fieldNames={{ value: 'id', label: 'name' }} defaultValue={gender} onChange={c => setGender(c)} status={getFieldStatus('gender_id', errors)} />
                            <Input type="hidden" name="gender_id" value={gender} />
                            {getFieldErrors('gender_id', errors)}
                        </div>
                        <div className={Styles.inputField}>
                            <label>First Name</label>
                            <Input name="first_name" defaultValue={data.customer.first_name} status={getFieldStatus('first_name', errors)} />
                            {getFieldErrors('first_name', errors)}
                        </div>
                        <div className={Styles.inputField}>
                            <label>Last Name</label>
                            <Input name="last_name" defaultValue={data.customer.last_name} status={getFieldStatus('last_name', errors)} />
                            {getFieldErrors('last_name', errors)}
                        </div>
                        <div className={Styles.inputField}>
                            <label>Email</label>
                            <Input name="email" defaultValue={data.customer.email} status={getFieldStatus('email', errors)} />
                            {getFieldErrors('email', errors)}
                        </div>
                        <div className={Styles.inputField}>
                            <label>VAT Number</label>
                            <Input name="vat_number" defaultValue={data.customer.vat_number} status={getFieldStatus('vat_number', errors)} />
                            {getFieldErrors('vat_number', errors)}
                        </div>
                    </div>
                </div>
                <div className={Styles.mainWrapper}>
                    <div className={Styles.fieldsWrapper}>
                        <div className={Styles.inputField}>
                            <label>Street</label>
                            <Input name="street" defaultValue={data.customer.street} status={getFieldStatus('street', errors)} />
                            {getFieldErrors('street', errors)}
                        </div>
                        <div className={Styles.inputField}>
                            <label>Street No.</label>
                            <Input name="street_number" defaultValue={data.customer.street_number} status={getFieldStatus('street_number', errors)} />
                            {getFieldErrors('street_number', errors)}
                        </div>
                        <div className={Styles.inputField}>
                            <label>Zipcode</label>
                            <Input name="zipcode" defaultValue={data.customer.zipcode} status={getFieldStatus('zipcode', errors)} />
                            {getFieldErrors('zipcode', errors)}
                        </div>
                        <div className={Styles.inputField}>
                            <label>City</label>
                            <Input name="city" defaultValue={data.customer.city} status={getFieldStatus('city', errors)} />
                            {getFieldErrors('city', errors)}
                        </div>
                        <div className={Styles.inputField}>
                            <label>County</label>
                            <Input name="county" defaultValue={data.customer.county} status={getFieldStatus('county', errors)} />
                            {getFieldErrors('county', errors)}
                        </div>
                        <div className={Styles.inputField}>
                            <label>Country</label>
                            <CountrySelect onChange={c => setCountry(c)} defaultValue={country} />
                            <Input type="hidden" name="country_id" value={country} status={getFieldStatus('country_id', errors)} />
                            {getFieldErrors('country_id', errors)}
                        </div>
                        <div className={Styles.inputField}>
                            <label>Address Rule 1</label>
                            <Input name="address_rule_1" defaultValue={data.customer.address_rule_1} status={getFieldStatus('address_rule_1', errors)} />
                            {getFieldErrors('address_rule_1', errors)}
                        </div>
                        <div className={Styles.inputField}>
                            <label>Address Rule 2</label>
                            <Input name="address_rule_2" defaultValue={data.customer.address_rule_2} status={getFieldStatus('address_rule_2', errors)} />
                            {getFieldErrors('address_rule_2', errors)}
                        </div>
                    </div>
                </div>
                <div className={Styles.mainWrapper}>
                    <div className={Styles.fieldsWrapper}>
                        <div className={Styles.inputField}>
                            <label>Invoice Date</label>
                            <DatePicker
                                defaultValue={moment(data.invoice_date, formats.backendDateFormat)}
                                format={formats.frontendDateFormat} onChange={(v) => {
                                    setInvoiceDate(v.format(formats.backendDateFormat))
                                }}
                                disabledDate={(current) => {
                                    return moment(expiryDate, formats.backendDateFormat) && moment(expiryDate, formats.backendDateFormat) < current
                                }}
                            />
                            <input type="hidden" name="invoice_date" value={invoiceDate} />
                        </div>
                        <div className={Styles.inputField}>
                            <label>Expiry Date</label>
                            <DatePicker
                                defaultValue={moment(data.expiry_date, formats.backendDateFormat)}
                                format={formats.frontendDateFormat} onChange={(v) => {
                                    setExpiryDate(v.format(formats.backendDateFormat))
                                }}
                                disabledDate={(current) => {
                                    return moment(invoiceDate, formats.backendDateFormat) && moment(invoiceDate, formats.backendDateFormat) > current
                                }}
                            />
                            <input type="hidden" name="expiry_date" value={expiryDate} />
                        </div>
                        <div className={Styles.inputField}>
                            <label>Leiferdatum</label>
                            <Input name="leiferdatum" defaultValue={data.leiferdatum} />
                        </div>
                    </div>
                </div>
                <div className={Styles.mainWrapper}>
                    <div className={Styles.fieldsWrapper}>
                        <div className={Styles.inputField}>
                            <label>Client Order No.</label>
                            <Input name="client_order_number" defaultValue={data.client_order_number} />
                        </div>
                        <div className={Styles.inputField}>
                            <label>Invoice Second Contact</label>
                            <ContactSelect
                                customerId={data.customer_id}
                                onSelect={(_, contact) => {
                                    setSecondContact(contact)
                                    // setShowContactSelect(false)
                                }}
                                defaultValue={data.invoice_second_contact_id}
                                excludedContactIds={[]}
                                renderSelected={() => (
                                    <>
                                        <Input value={secondContact?.email ?? ''} status={getFieldErrors('invoice_second_contact_id', errors)} />
                                        <input type="hidden" name="invoice_second_contact_id" value={secondContact?.id} />
                                        {getFieldErrors('invoice_second_contact_id', errors)}
                                    </>
                                )}
                            />
                        </div>
                        <div className={Styles['inputField--span2']}>
                            <label>Footer</label>
                            <Input.TextArea name="footer" key={selectedFooterTemplated.value} defaultValue={selectedFooterTemplated.value ?? data.footer} />
                            {getFieldErrors('footer', errors)}
                        </div>
                        <div className={Styles.inputField}>
                            <label> </label>
                            <Button onClick={() => setShowFooterSelect(true)} >Select from template</Button>
                            {/* <Input type="hidden" name="footer_text" value={selectedFooterTemplated?.id} /> */}
                        </div>
                    </div>
                </div>
                <div className={Styles.actionsWrapper}>
                    <Button type="primary" htmlType="submit">Save</Button>
                    <Button onClick={onCancel}>Cancel</Button>
                </div>
            </Form>
            {showFooterSelect && (
                <SelectableTextTemplate show={showFooterSelect} handleClose={() => setShowFooterSelect(false)} onSelectionChanged={(selectedText) => {
                    setSelectedFooterTemplate(selectedText)
                    setShowFooterSelect(false)
                }} fieldTypeId={1} />
            )}
        </>
    )
}
UpdateInvoiceBasicDetails.propTypes = {
    data: PropTypes.object.isRequired,
    errors: PropTypes.object,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
}

export { InvoiceBasicDetails, UpdateInvoiceBasicDetails };

