import { Tag } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const getFilterValue = (val) => typeof val === 'object' ? val.name : val

const SelectedFilters = ({ filters, filterNames, updateFilters }) => (
    filters && Object.entries(filters).map(([filter, val]) => (
        <Tag className='filter-tags' key={filter} onClose={() => updateFilters(({ [filter]: _, ...rest }) => rest)} closable>
            <span style={{ maxWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{filterNames[filter] || filter}: {Array.isArray(val) ? val.map(getFilterValue).join(', ') : getFilterValue(val)}</span>
        </Tag>
    ))
)

SelectedFilters.propTypes = {
    filters: PropTypes.object.isRequired,
    filterNames: PropTypes.object.isRequired,
    updateFilters: PropTypes.func.isRequired,
}

export { SelectedFilters };
