import {
	Input,
} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { ValidationForm as Form } from '../';
import { CountrySelect } from '../../inputs';
import styles from './vendor-address.module.scss';
import { normalizeAlphaNumeric } from '../../../library/helpers/normalize';

const VendorAddress = ({ children, index, getValidationStatus, ...otherProps }) => {
	const [form] = Form.useForm()
	const validationRules = {
		street: [
			{
				required: true,
				message: 'Street is required'
			}
		],
		street_number: [
			{
				required: true,
				message: 'Street number is required'
			}
		],
		city: [
			{
				required: true,
				message: 'City is required'
			}
		],
		zipcode: [
			{
				required: true,
				message: 'Zipcode is required'
			}
		],
		country_id: [
			{
				required: true,
				message: 'Country is required'
			}
		],
	}
	// const normalizeValue = (value) => {
	// 	// Allow letters, numbers, spaces, and hyphens
	// 	const filteredValue = value.replace(/[^a-zA-Z0-9\s-]/g, '');
	// 	return filteredValue;
	// };

	return (
		<Form form={form} name={`address-${index}`} onValidationStatusChange={getValidationStatus} layout="vertical" className={styles.FormWrapper} validationRules={validationRules} validateTrigger={['onChange', 'onBlur']} {...otherProps}>
			<Form.Item name="street" label="Street">
				<Input />
			</Form.Item>
			<Form.Item name="street_number" label="Street Number">
				<Input />
			</Form.Item>
			<Form.Item name="city" label="City">
				<Input type="text" />
			</Form.Item>
			<Form.Item name='county' label="County">
				<Input type="text" />
			</Form.Item>
			<Form.Item name='country_id' label="Country">
				<CountrySelect />
			</Form.Item>
			<Form.Item name="zipcode" label="Zipcode" normalize={normalizeAlphaNumeric}>
				<Input type="text" maxLength={15} />
			</Form.Item>
			{children}
		</Form>
	)
}

VendorAddress.propTypes = {
	children: PropTypes.node,
	index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	getValidationStatus: PropTypes.func,
}

export default VendorAddress