import {
	Input
} from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ValidationForm as Form } from '../';
import { checkContactDuplicacy } from '../../../api/contact/contact.js';
import { generateRouteUrl } from '../../../library/constants/routes.js';
import { GenderSelect, GeneralFieldWrapper, LanguageSelect, PhoneInput, RoleSelect } from '../../inputs';
import PhoneDisplay from '../../inputs/PhoneDisplay.jsx';
import styles from './customer-contact.module.scss';
import { normalizeEmail } from '../../../library/helpers/normalize.js';

const CustomerContactDisabled = ({ data = {} }) => (
	<div className="form-fields-wrapper form-fields-wrapper" style={{ alignItems: 'start' }}>
		<GeneralFieldWrapper label="First Name">{data.first_name}</GeneralFieldWrapper>
		<GeneralFieldWrapper label="Last Name">{data.last_name}</GeneralFieldWrapper>
		<div className=""></div>
		<GeneralFieldWrapper label="Email Address">{data.email}</GeneralFieldWrapper>
		<GeneralFieldWrapper label="Phone Number"><PhoneDisplay code={data.phone_country_code} number={data.phone_number} /></GeneralFieldWrapper>
		<GeneralFieldWrapper label="Mobile Number"><PhoneDisplay code={data.mobile_country_code} number={data.mobile_number} /></GeneralFieldWrapper>
		<GeneralFieldWrapper label="Gender">{data.gender?.name}</GeneralFieldWrapper>
		<GeneralFieldWrapper label="Role in Company">{data.role?.name}</GeneralFieldWrapper>
		<GeneralFieldWrapper label="Language">{data.language?.name}</GeneralFieldWrapper>
	</div>
)

CustomerContactDisabled.propTypes = {
	data: PropTypes.object.isRequired
}

const duplicacyChecker = async (field, value, originalValue) => {
	//Don't check on update form when value is unchanged
	if (originalValue && value === originalValue) {
		return Promise.resolve()
	}
	const data = await checkContactDuplicacy(field, value)
	if (data && data.id) {
		return Promise.reject(<>{field} is already being used <Link className="blue" to={generateRouteUrl('ContactDetails', {
			id: data.id
		})}>View here</Link></>)
	}
	else {
		return Promise.resolve()
	}
}

const CustomerContact = ({ children, index, allowedLanguages, getValidationStatus, ...otherProps }) => {
	// otherProps.initialValues.mobile_country_code = getCountryById(country)?.phone_number_code
	// otherProps.initialValues.phone_country_code = getCountryById(country)?.phone_number_code

	const [form] = Form.useForm()
	const validationRules = {
		first_name: [
			{
				required: true,
				message: 'First Name is required'
			}
		],
		last_name: [
			{
				required: true,
				message: 'Last Name is required'
			}
		],
		email: [
			{
				required: true,
				message: 'Email is required'
			},
			{
				type: 'email',
				message: 'Email is not valid'
			},
			{
				validator: ({ field }, value) => duplicacyChecker(field, value, otherProps?.initialValues?.[field]),
			}
		],
		gender_id: [
			{
				required: true,
				message: 'Gender is required'
			}
		],
		company_role: [
			{
				required: true,
				message: 'Role is required'
			}
		],
		language_id: [
			{
				required: true,
				message: 'Language is required'
			}
		],
	}
	useEffect(() => {
		if (allowedLanguages.length === 1) {
			form.setFieldValue('language_id', parseInt(allowedLanguages[0]));
		}
	}, [allowedLanguages]);

	if (otherProps.disabled) {
		return (
			<CustomerContactDisabled data={otherProps?.initialValues} />
		)
	}

	return (
		<Form form={form}
			name={`contact-${index}`}
			layout="vertical"
			className={styles.FormWrapper}
			onValidationStatusChange={getValidationStatus}
			validationRules={validationRules}
			validateTrigger={['onChange', 'onBlur']}
			{...otherProps}
		>
			<Form.Item name="first_name" label="First Name" >
				<Input placeholder="First Name" onKeyPress={(event) => {
					if (/[0-9]/.test(event.key)) {
						event.preventDefault();
					}
				}} />
			</Form.Item>
			<Form.Item name="last_name" label="Last Name" >
				<Input placeholder="Last Name" onKeyPress={(event) => {
					if (/[0-9]/.test(event.key)) {
						event.preventDefault();
					}
				}} />
			</Form.Item>
			<br />
			<Form.Item name="email" label="Email Address" normalize={normalizeEmail}>
				<Input type="email" placeholder="Email" />
			</Form.Item>
			<Form.Item label="Phone Number" required>
				<PhoneInput valuePropName={{ code: 'phone_country_code', number: 'phone_number' }} placeholder="Telephone No." label='Phone Number' required />
			</Form.Item>
			<Form.Item label="Mobile Number">
				<PhoneInput valuePropName={{ code: 'mobile_country_code', number: 'mobile_number' }} placeholder="Mobile No." label='Mobile Number' />
			</Form.Item>
			<Form.Item name='gender_id' label="Gender" >
				<GenderSelect />
			</Form.Item>
			<Form.Item name='company_role' label="Role in Company" >
				<RoleSelect />
			</Form.Item>
			<Form.Item name='language_id' label="Language" >
				<LanguageSelect options={allowedLanguages?.map(lang => parseInt(lang))} />
			</Form.Item>
			{children}
		</Form>
	)
}
//suffixIcon={<Icon component={() => (<img src="/icons/dropdown.svg" />)} styles={{pointerEvents: 'none'}} />}
CustomerContact.propTypes = {
	children: PropTypes.node,
	index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	getValidationStatus: PropTypes.func,
	form: PropTypes.object,
	allowedLanguages: PropTypes.array
}

export default CustomerContact