import { Button, Collapse, message } from 'antd';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useActionData, useFetcher, useLoaderData } from 'react-router-dom';
import { uploadFileFromObject } from '../../api/common';
import { sendArtworktoOrder, sendProoftoOrder } from '../../api/studio';
import { getStudioDetails, sendMakeArtworktoOrder, sendTemplateToOrder } from '../../api/studio/studio';
import { ItemSpecifications } from '../../components/design';
import { DocumentsDownloader } from '../../components/form';
import { GeneralFieldWrapper } from '../../components/inputs/input-wrappers.jsx';
import { Page } from '../../components/page';
import { ActionError } from '../../library/classes/exceptions';
import { studioStatus } from '../../library/constants';
import { StudioKanabanColumn } from '../../library/constants/kanabanColumns/studioKanabanColumn.js';
import { getLanguageById } from '../../library/helpers/language';
import { productUrl } from '../../library/helpers/product_url';
import { AddFileUpload, Message, MessageTypes } from '../../ui';
import styles from './studio-item.module.scss';
const { Panel } = Collapse


const StudioItemDetails = ({ title }) => {
    const { studioDetails, url, uploadable } = useLoaderData();
    const actionData = useActionData()
    const fileUploaderRef = useRef([])
    const fetcher = useFetcher()
    const statusActions = {
        1: 'sendRevisedTemplate',
        3: 'sendRevisedArtwork',
        5: 'sendRevisedProof',
        7: 'sendMakeArtowork'

    }
    const handleFilesUpload = async () => {
        if (fileUploaderRef.current.length === 0) {
            alert('Please select atleast one file')
            return
        }
        const promises = fileUploaderRef.current.map(({ file }) => uploadFileFromObject(file))
        try {
            const fileIds = await Promise.all(promises)
            const imagesIdData = fileIds.map((id, index) => ({
                id,
                comment: fileUploaderRef.current[index].comment
            }))
            fetcher.submit({
                imagesIdData,
                action: statusActions[studioDetails.studio_status]
            }, {
                method: 'POST',
                encType: "application/json"
            })
            fileUploaderRef.current = []
        }
        catch (error) {
            throw new Response('Unable to upload files', { status: 500 })
        }
    }

    return (
        <Page title={title}  >
            <Collapse defaultActiveKey={['1']} expandIconPosition="end">
                <Panel header='Item Details' key="1">
                    <h2>{studioDetails?.product?.name}</h2>
                    <p>Product Link from vendor <a href={url} target='blank' style={{ color: '#1491ff' }}>{url}</a></p>
                    <ItemSpecifications photo={studioDetails.product?.main_photos?.[0]} specs={studioDetails?.specification} />
                    <br />
                    {studioDetails.request_comment?.length > 0 && (
                        <GeneralFieldWrapper label="Comment from Order Manager:">{studioDetails.request_comment}</GeneralFieldWrapper>
                    )}
                </Panel>
            </Collapse>
            {studioDetails.previous_files?.length > 0 && (
                <Collapse defaultActiveKey={['1']} expandIconPosition="end">
                    <Panel header="Recieved Files" key="1">
                        {studioDetails.previous_files && studioDetails.previous_files.map((docs) => {
                            return (
                                <DocumentsDownloader key={docs.url} image={docs?.url} name={docs?.name} comment={docs?.comment} project_id={studioDetails.project_id} />
                            )
                        })}
                    </Panel>
                </Collapse>
            )}
            {studioDetails.available_documents?.length > 0 && (
                <Collapse defaultActiveKey={['1']} expandIconPosition="end">
                    <Panel header={StudioKanabanColumn.find(({ key }) => key === studioDetails.studio_status)?.title} key="1">
                        {studioDetails.available_documents && studioDetails.available_documents.map((docs) => {
                            return (
                                <DocumentsDownloader key={docs.url} image={docs?.url} name={docs?.name} comment={docs?.comment} project_id={studioDetails.project_id} />
                            )
                        })}
                    </Panel>
                </Collapse>
            )}
            {uploadable && <>
                <Collapse defaultActiveKey={['1']} expandIconPosition="end">
                    <Panel header='Upload Revised Documents' key="1">
                        <div>
                            {!!actionData && actionData instanceof ActionError && <Message type={MessageTypes.ERROR} message={actionData.message} timeOut={3} />}
                            {actionData === true && <Message type={MessageTypes.SUCCESS} message="File Uploaded Successfully" timeOut={3} />}
                            <AddFileUpload ref={fileUploaderRef} />
                        </div>
                    </Panel>
                </Collapse>
                <Button type='primary' className={styles.send} onClick={() => {
                    handleFilesUpload()
                }} loading={fetcher.state !== 'idle'}>Send</Button></>}
        </Page>
    )
}
StudioItemDetails.Loader = async ({ params }) => {
    if (isNaN(params.id)) {
        throw new Error('Invalid Item ID')
    }
    const studioDetails = await getStudioDetails(params.id)
    if (!studioDetails) {
        throw new Error('Invalid Item')
    }

    const uploadable = (studioDetails.studio_status == studioStatus.SEND_TEMPLATE || studioDetails.studio_status == studioStatus.SEND_ARTWORK || studioDetails.studio_status == studioStatus.SEND_PROOF || studioDetails.studio_status == studioStatus.SEND_MAKE_ARTWORK)
    const code = getLanguageById(studioDetails?.project?.language_id) ?? 'en'
    const url = studioDetails?.product?.product_link ? productUrl(studioDetails.product.product_link, code.cms_language_code) : '';

    return { studioDetails, url, uploadable }
}

StudioItemDetails.Actions = {
    sendRevisedTemplate: async ({ params, data }) => {
        const { id } = params
        const { imagesIdData } = data;
        try {
            const { message: msg } = await sendTemplateToOrder(id, { image_ids: imagesIdData })
            message.success(msg)
            return true
        }
        catch (e) {
            message.error(e.message)
            throw e
        }
    },
    sendRevisedArtwork: async ({ params, data }) => {
        const { id } = params
        const { imagesIdData } = data;
        try {
            const { message: msg } = await sendArtworktoOrder(id, { image_ids: imagesIdData })
            message.success(msg)

            return true
        }
        catch (e) {
            message.error(e.message)
            throw e
        }
        // return true
    },
    sendRevisedProof: async ({ params, data }) => {
        const { id } = params
        const { imagesIdData } = data;
        try {
            const { message: msg } = await sendProoftoOrder(id, { image_ids: imagesIdData })
            message.success(msg)

            return true
        }
        catch (e) {
            message.error(e.message)
            throw e
        }
    },
    sendMakeArtowork: async ({ params, data }) => {
        const { id } = params
        const { imagesIdData } = data;
        try {
            const { message: msg } = await sendMakeArtworktoOrder(id, { image_ids: imagesIdData })
            message.success(msg)

            return true
        }
        catch (e) {
            message.error(e.message)
            throw e
        }
    },
}

StudioItemDetails.propTypes = {
    title: PropTypes.string,
}

export default StudioItemDetails