import { DeleteOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { isNil, omitBy } from 'lodash-es';
import PropTypes from 'prop-types';
import React from 'react';
import { ValidationForm as Form } from '../../form';
// import { ValidationForm as Form } from '../';
import { ERP_CURRENCY } from '../../../library/constants/dynamic';
import SmallSection from '../../design/small-section.jsx';
import { Currency, CurrencyConversion, NameSelect, Percent } from '../../inputs';
import { ReasonStatus } from '../../inputs/NameSelect.jsx';
import Confirm from '../../message/Confirm';
import styles from './addition-quote.module.scss';
const { TextArea } = Input

export const AdditionalQuotationReasons = {
    DELIVERY: 1,
    IMPORTFEES: 2,
    SPECSCHANGE: 3,
    STUDIOINVOLVEMENT: 4,
    OTHER: 5
}

const calculateSellingPrice = (values) => {
    const { price = 0.00, margin = 0 } = values
    const totalCost = (price)
    return (totalCost / (1 - margin / 100))
}
// const calculatePurchasePrice = (values) => {
//     const { sales_price = 0.00, margin = 0 } = values

//     return (sales_price * (1 - margin / 100))
// }
const calculateMargin = (values) => {
    const { price = 0.00, sales_price = 0.00 } = values
    // const totalPrice = ((purchase_price * quantity) + finisher_price + handling_charge + shipping_charge)
    const totalCost = (price)
    if (totalCost === 0) {
        return 0
    }
    return (1 - (totalCost / sales_price)) * 100
}

const AdditionalQuoteForm = ({ children, index, onFormChange, onDelete, conversion_rate, projectCurrencyCode, errors, ...otherProps }) => {
    const [form] = Form.useForm()
    const reason = Form.useWatch('reason', form)

    const projectCurrencyDiffers = projectCurrencyCode !== ERP_CURRENCY
    const projectCurrencyRate = conversion_rate?.[projectCurrencyCode];

    const handleValueChange = (changedFields, values) => {
        const fieldName = Object.keys(changedFields)[0];
        const updatedValues = omitBy(values, isNil)

        if (fieldName === 'sales_price') {
            //Update selling price directly
            const _margin = calculateMargin(values)
            // const _purchase = calculatePurchasePrice(values)
            form.setFieldValue('margin', _margin)
            // form.setFieldValue('price', _purchase)
            updatedValues.margin = _margin
        }
        else {
            const _customSellingPrice = calculateSellingPrice(values)
            form.setFieldValue('sales_price', _customSellingPrice)
            updatedValues.sales_price = _customSellingPrice
        }
        if (reason !== AdditionalQuotationReasons.OTHER) {
            updatedValues.name = ReasonStatus[reason]
        }
        // delete requiredValues.sales_price
        onFormChange(updatedValues)
    }
    const validationRules = {
        reason: [
            {
                required: true
            }
        ],
        price: [
            {
                required: true
            }
        ],
        sales_price: [
            {
                required: true
            }
        ],
        margin: [
            {
                required: false
            }
        ],
        description: [
            {
                required: false
            }
        ],
    }

    return (
        <SmallSection>
            <Form onValuesChange={handleValueChange} validationRules={validationRules} form={form} name={`quote-${index}`} className={styles.FormWrapper} errors={errors} {...otherProps} >
                <Form.Item hidden name="item_notification_id">
                    <Input type="hidden" />
                </Form.Item>
                <div>
                    <Form.Item name="reason" className={styles.field} >
                        <NameSelect />
                    </Form.Item>
                    <br />
                    {reason === 5 && <Form.Item name="name" className={styles.field} >
                        <TextArea name="name" type="text" />
                    </Form.Item>}
                </div>
                <Form.Item name="description" className={styles.field} >
                    <TextArea name="description" type="text" maxLength={255}/>
                </Form.Item>
                <Form.Item name="price" className={styles.field}>
                    <Currency currency={ERP_CURRENCY} type='currency' controls={false} maxLength={20}/>
                </Form.Item>
                <Form.Item name="margin" className={styles.field}>
                    <Percent />
                </Form.Item>
                <Form.Item name="sales_price" >
                    {projectCurrencyDiffers ?
                        <CurrencyConversion currency={ERP_CURRENCY} secondCurrency={projectCurrencyCode} conversionRate={projectCurrencyRate} type='currency' controls={false} /> :
                        <Currency currency={ERP_CURRENCY} controls={false} />
                    }
                </Form.Item>
                <Confirm icon={<DeleteOutlined />} onConfirm={onDelete} title="Delete Line" content="You will lose this quotation line, and this action cannot be undone. Do you really want to proceed ?" shape="square" danger ghost />
                {children}
            </Form>
        </SmallSection>
    )
}

AdditionalQuoteForm.propTypes = {
    children: PropTypes.node,
    index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    values: PropTypes.object,
    conversion_rate: PropTypes.object,
    projectCurrencyCode: PropTypes.string,
    errors: PropTypes.object,
    onFormChange: PropTypes.func,
    onDelete: PropTypes.func,
    // canUpdate: PropTypes.bool
}

export default AdditionalQuoteForm



{/* <Form.Item name="purchase_price" label= "Currency name="price" type='number' value={price} onChange={event => onFormChange(index, event)} />
</Form.Item>
<Form.Item name="margin">
    <Percent type='percent' controls={false} />
</Form.Item>
<Form.Item name="total_price">
    <Currency currency={currency.code} type='currency' controls={false} />
</Form.Item> */}