export const normalizeAlphaNumeric = (value) => {
    // Allow letters, numbers, spaces, hyphens, and specific special characters (@, #, !, $, %)
    const filteredValue = value.replace(/[^a-zA-Z0-9\s\-@#!$%]/g, '');
    return filteredValue;
};

export const normalizeEmail = (value) => {
    const filteredValue = value.replace(/[^a-zA-Z0-9@.]/g, '');
    return filteredValue;
};

export const normalizeWebsite = (value) => {
    const filteredValue = value.replace(/[^a-zA-Z0-9-.\s]/g, '');
    return filteredValue;
};