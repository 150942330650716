import React from 'react';
import { Link } from 'react-router-dom';
import { getFormattedId } from '../../helpers/index.js';
import { formatDate, formatPrice } from '../../utilities/intl.js';
import { ERP_CURRENCY } from '../dynamic.js';
import { generateRouteUrl } from '../routes.js';
import { Server } from '../server.js';

export const SepaPaymentColumns = [
    {
        title: 'ID',
        dataIndex: 'id',
        sorter: true,
        fixed: 'left',
    },
    {
        title: 'Create Date',
        sorter: true,
        dataIndex: 'createdAt',
        render: date => formatDate(date)
    },
    {
        title: 'Executive Entity',
        dataIndex: ['entity', 'name']
    },
    {
        title: 'Total Payment',
        dataIndex: 'amount',
        render: (amount) => formatPrice(amount, ERP_CURRENCY)
    },
    {
        title: 'Transactions',
        dataIndex: 'transaction',
    },
    {
        title: 'Status',
        dataIndex: 'paid',
        render: (paid) => <div className={`status-tag status-tag--${paid ? 'paid' : 'unpaid'}`}>{paid ? 'Paid' : 'Unapid'}</div>
    },
    {
        title: 'Notes',
        dataIndex: 'notes',
        ellipsis: true,
    },
    {
        title: 'Paid By',
        dataIndex: ['paid_by_user', 'username'],
    },
]

export const getPurchaseBillColumns = (currency) => (
    [
        {
            title: 'Purchase Bill',
            dataIndex: 'id',
            render: id => <Link to={generateRouteUrl('FinancePurchaseBillDetails', { purchaseBillId: id })}>{getFormattedId(id)}</Link>
        },
        {
            title: 'Project',
            dataIndex: 'project_id',
            render: projectId => <Link to={generateRouteUrl('ProjectUpdate', { projectId })}>{getFormattedId(projectId)}</Link>
        },
        {
            title: 'Vendor',
            dataIndex: 'vendor_id',
            render: (vendor_id, record) => <Link to={generateRouteUrl('VendorUpdate', { id: vendor_id })}>{record.vendor.company}</Link>
        },
        {
            title: 'Invoice No.',
            dataIndex: 'invoice_number',
        },
        {
            title: 'Notes',
            dataIndex: 'description',
        },
        // {
        //     title: 'Payment Term',
        //     dataIndex: 'payment_term',
        // },
        {
            title: 'Creation Date',
            dataIndex: 'invoice_date',
            render: date => formatDate(date)
        },
        {
            title: 'Amount',
            dataIndex: 'total_price_incl_vat',
            render: amount => formatPrice(amount, currency)
        },
        {
            title: 'Custom Amount',
            dataIndex: 'custom_amount_to_pay',
            render: amount => formatPrice(amount, currency)
        },
        {
            title: 'Due Date',
            dataIndex: 'due_date',
            render: date => formatDate(date)
        },
        {
            title: 'File',
            dataIndex: ['email_read', 'attachments'],
            render: attachments => {
                if (!attachments) return
                return attachments.map(attachment => (
                    <Link key={attachment} to={Server.getFileUrl(`document/attachment/${attachment}`)} target="_blank" reloadDocument download>View</Link>
                ))
            }
        }
    ]
)