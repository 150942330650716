import { Button, Drawer, Input, message, Select } from 'antd';
import React, { useState } from 'react';
import { Form, redirect, useActionData, useNavigate } from 'react-router-dom';
import { createGeneralLedger } from '../../api/settings/general-ledger.js';
import ExecutiveEntitySelect from '../../components/inputs/ExecutiveEntitySelect.js';
import { ValidationError } from '../../library/classes/exceptions/index.js';
import { VatTypes } from '../../library/constants/dynamic.js';
import { generateRouteUrl } from '../../library/constants/routes.js';
import { getFieldErrors, getFieldStatus } from '../../library/helpers/forms/index.js';
import { convertDataTypes } from '../../library/helpers/index.js';
import Styles from '../users/user-details.module.scss';
const { Option } = Select;

const LedgerCreate = () => {
    const navigate = useNavigate()
    const { errors = null } = useActionData() || {}
    const [entity, setEntity] = useState()
    const [vatId, setVatId] = useState()
    const [category, setCategory] = useState();
    const [vatCategory, setVatCategory] = useState();

    const categoryOptions = {
        1: "Sales",
        2: "Purchase"
    };
    // const vatCategoryLabels = {
    //     '1': 'Zero',
    //     '2': 'Low',
    //     '3': 'High'
    // };
    return (
        <Drawer open={true} mask={false} title="Create Ledger" onClose={() => navigate('..', { replace: true, relative: 'route' })} >
            <Form method="post" className={Styles.formWrapper}>
                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.inputField}>
                        <label>Name</label>
                        <Input type="text" name="name" status={getFieldStatus('name', errors)} />
                        {getFieldErrors('name', errors)}
                    </div>
                </div>
                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.inputField}>
                        <label>Entity</label>
                        <ExecutiveEntitySelect
                            value={entity}
                            onChange={(val) => {
                                setEntity(val);
                            }}
                            status={getFieldStatus('entity_id', errors)}
                        />
                        <input type="hidden" name="entity_id" value={entity || ''} />
                        {getFieldErrors('entity_id', errors)}
                    </div>
                </div>
                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.inputField}>
                        <label>Category</label>
                        <Select
                            name="category"
                            status={getFieldStatus('category', errors)}
                            onChange={(c) => setCategory(c)}
                        >
                            {Object.entries(categoryOptions).map(([value, label]) => (
                                <Option key={value} value={value}>
                                    {label}
                                </Option>
                            ))}
                        </Select>
                        <input type="hidden" name="category" value={category || ''} />
                        {getFieldErrors('category', errors)}
                    </div>
                </div>
                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.inputField}>
                        <label>VAT Category</label>
                        <Select
                            name="vat_category"
                            status={getFieldStatus('vat_category', errors)}
                            onChange={(c) => setVatCategory(c)}
                        >
                            <Option value='1'> Zero</Option>
                            <Option value='2'> Low</Option>
                            <Option value='3'> High</Option>
                        </Select>
                        <input type="hidden" name="vat_category" value={vatCategory || ''} />
                        {getFieldErrors('vat_category', errors)}
                    </div>
                </div>

                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.inputField}>
                        <label>VAT Type</label>
                        <Select
                            options={VatTypes.filter(({ entity_id }) => entity_id === entity)}
                            fieldNames={{ value: 'id', label: 'description' }}
                            onChange={c => setVatId(c)}
                            status={getFieldStatus('vat_type_id', errors)}
                        />
                        <input type="hidden" name="vat_type_id" value={vatId || ''} />
                        {getFieldErrors('vat_type_id', errors)}
                    </div>
                </div>
                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.inputField}>
                        <label>Ledget Text</label>
                        <Input type="text" name="ledger_text" status={getFieldStatus('ledger_text', errors)} />
                        {getFieldErrors('ledger_text', errors)}
                    </div>
                </div>
                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.inputField}>
                        <label>Ledget Text En</label>
                        <Input type="text" name="ledger_text_en" status={getFieldStatus('ledget_text_en', errors)} />
                        {getFieldErrors('ledget_text_en', errors)}
                    </div>
                </div>

                <Button type='primary' htmlType='submit'>Create Ledger</Button>
            </Form>
        </Drawer >
    )
}

LedgerCreate.Action = async ({ request }) => {
    try {
        const data = Object.fromEntries(await request.formData())
        const payload = convertDataTypes(data, {
            entity_id: v => (parseInt(v) || undefined),
            vat_type_id: parseInt,
            vat_category: parseInt,
            category: parseInt,
        })

        //Todo: Basic validation
        const { message: msg, data: generalLedgerId } = await createGeneralLedger(payload)
        message.success(msg)
        return redirect(generateRouteUrl('LedgerDetails', {
            id: generalLedgerId
        }), {
            replace: true
        })
    }
    catch (error) {
        message.error(error.message)
        if (error instanceof ValidationError) {
            return {
                errors: error.errors
            }
        }
    }
    return false
}

export default LedgerCreate