import { FilterOutlined, ReloadOutlined } from '@ant-design/icons';
import {
    Button, Form, Input,
} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useLoaderData } from 'react-router-dom';
import { convertDataTypes } from '../../../library/helpers/index.js';
import { CompanySelect, } from '../../inputs';


const EulerFilterForm = ({ children, data, ...otherProps }) => {
    const { meta } = useLoaderData()
    const [form] = Form.useForm()

    const clearForm = () => {
        try {
            form.setFieldsValue(Object.fromEntries(
                Object.keys(form.getFieldsValue()).map(key => ([key, null]))
            ))
            form.submit()
        }
        catch (e) {
            console.log(e);
        }
    }

    return (
        <Form form={form} initialValues={convertDataTypes(data, {
            customer_id: parseInt,
        })} layout='vertical'  {...otherProps} >
            <Form.Item name="customer_id" label="Customer  ">
                <CompanySelect currentSelection={meta?.filters?.customer_id?.id ? { value: meta.filters.customer_id.id, label: meta.filters.customer_id.name } : null} getPopupContainer={triggerNode => triggerNode.parentElement}/>
            </Form.Item>
            <Form.Item name="case_number" label="Case Number">
                <Input />
            </Form.Item>
            <Form.Item name="invoice_no" label="Invoice No.">
                <Input />
            </Form.Item>
            <Form.Item name="createdAt" label="Created Date">
                <Input type='date' />
            </Form.Item>
            {/* <Form.Item name="status" label="Status" >
            <Select>
                    <Option value="New">New</Option>
                    <Option value="Paid">Paid</Option>
                    <Option value="Finalize">Finalize</Option>
                    <Option value="Send_to_client">Send To Client</Option>
                    <Option value="Send_to_euler">Send To Euler</Option>
                    <Option value="First_reminder">First Reminder</Option>
                    <Option value="Second_reminder">Second Reminder</Option>
                    <Option value="Third_reminder">Third Reminder</Option>
                    <Option value="Fourth_reminder">Fourth Reminder</Option>
                </Select>
            </Form.Item> */}


            {/* 
            <Form.Item name='language_id' label="Language">
                <LanguageSelect />
            </Form.Item> */}
            {children}
            <div className="actions">
                <Button htmlType="submit" type="primary" icon={<FilterOutlined />}>Apply</Button>
                <Button onClick={clearForm} icon={<ReloadOutlined />}>Reset</Button>
            </div>
        </Form>
    )
}

EulerFilterForm.propTypes = {
    children: PropTypes.node,
    data: PropTypes.object,
}

export default EulerFilterForm