import { AppstoreOutlined, BarsOutlined, FilterOutlined, GlobalOutlined, MailOutlined, MobileOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Drawer, Input, Segmented, Space, Spin } from 'antd';
import { debounce } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { generatePath, useLoaderData, useNavigate, useSearchParams } from 'react-router-dom';
import { getAllProjects, getProjectKanabanCount } from '../../api/project/project.js';
import { SelectedFilters } from '../../components/data/selected-filters.jsx';
import { fetchSmallWebsiteLogo } from '../../components/design/getWebsiteLogo.js';
import KanabanBoard from '../../components/design/kanaban.jsx';
import styles from '../../components/form/project/project-details.module.scss';
import ProjectFilterForm from '../../components/form/project/project-filter.jsx';
import { Page } from '../../components/page';
import { Table } from '../../components/table';
import { useFetch } from '../../hooks';
import { ProjectColumns, Server, generateRouteUrl, getUserSettings, setUserSettings } from '../../library/constants';
import { ProjectKanabanColumns } from '../../library/constants/kanabanColumns/projecKanabanColumns.js';
import { getFormattedId, searchParamsToObject } from '../../library/helpers/index.js';
import { formatPrice } from '../../library/utilities/intl.js';

const VIEW_MODES = Object.freeze({
  LIST: "list",
  KANABAN: "kanaban",
})

const FILTER_NAMES = {
  customer_id: "Customer",
  email: "Email",
  account_manager_id: "Account Manager",
  country_id: "Country",
  language_id: "Language",
  entity_id: "Executive Entity",
  order_manager_id: "Order Manager",
  source: "Source",
  status: "Status",
}

const ProjectsListView = ({ data, isLoading = false, hasMore, onChangeSort, onLoadMore, onItemClick }) => {

  return (
    <div >
      <Table
        columns={ProjectColumns}
        data={data}
        isLoading={isLoading}
        onChange={onChangeSort}
        onRowClick={(_, record) => onItemClick(record)}
        hasMore={hasMore}
        loadMore={onLoadMore}
      />
    </div>
  )
}
ProjectsListView.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool,
  hasMore: PropTypes.bool.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  onItemClick: PropTypes.func.isRequired
}

const renderKanabanCard = ({ item, onClick }) => {
  const getSourceIcon = () => {
    switch (item.source_id) {
      case 'email':
        return <MailOutlined className={styles.mail} />
      case 'website':
        return <GlobalOutlined className={styles.globe} />
      case 'phone':
        return <MobileOutlined className={styles.phone} />
    }
  }
  return (<div className='kanaban__card' onClick={() => onClick(item)}>
    {/* <div className="kanaban__card__header">
      <p className='priority'>Priority : High</p>
    </div> */}
    <div className="kanaban__card__body">
      <div className="source">
        <div className="numberIcon">
          <span className="project-number">{getFormattedId(item.id)}</span>
          <span className="project-source">{getSourceIcon()}</span>
        </div>
        <span className="project-country">
          <img src={`${Server.BaseUrl}/${item.customer.country.flag_url}`} alt={item.customer.country.name} title={item.customer.country.name} />
        </span>
      </div>
      <span className="customer-logo">
        {fetchSmallWebsiteLogo(item.customer.website, item.customer.company)}
      </span>
    </div>

    <div className="kanaban__card__footer">
      <div className="details">
        <p className='head'>Account Mananger</p>
        <p className='leftHead'>Total Price</p>
        <p className='value'>{item.user?.name}</p>
        <p className='leftValue'>{formatPrice(item?.total_price ? item?.total_price : '0', 'EUR')}</p>
      </div>
    </div>

  </div>
  );
};

const ProjectsKanabanView = ({ data, kanabanCount, isLoading = false, hasMore, onLoadMore, onItemClick }) => {
  const columns = ProjectKanabanColumns.map(column => ({
    ...column,
    items: data.filter(({ status, cancelation_request }) => (status === column.key || column.key === 100 && !!cancelation_request?.length)) ?? [],
    count: kanabanCount?.find(({ status }) => status === column.key)?.count
  }))

  if (isLoading) {
    return (
      <Spin />
    )
  }

  return (
    <KanabanBoard
      columns={columns}
      onItemClick={onItemClick}
      renderItem={renderKanabanCard}
      hasMore={hasMore}
      loadMore={onLoadMore}
    />
  )
}
ProjectsKanabanView.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  kanabanCount: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool,
  hasMore: PropTypes.bool.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  onItemClick: PropTypes.func.isRequired
}

function Projects(props) {
  const initialData = useLoaderData()
  const [searchParams, setSearchParams] = useSearchParams()
  const [isFiltersVisible, setIsFiltersVisible] = useState(false)
  const navigate = useNavigate()
  //const [filtersVisible, setFiltersVisible] = useState(false)
  const [list, hasMore, isLoading, searchParamsParsed, {
    setSort,
    setFilters,
    loadMore
  }] = useFetch({ initialData })

  const debouncedChangeHandler = debounce(q => setFilters({ search: q }), 500)

  const handleRowClick = (record) => {
    navigate(generatePath('/projects/:id', {
      id: record.id
    }))
  }
  const viewMode = searchParams.get('view') ?? getUserSettings('projectListView') ?? VIEW_MODES.KANABAN
  const setViewMode = (mode) => setSearchParams(sp => {
    sp.set('view', mode)
    return sp
  })

  return (
    <Page className='projects-list' backLink={false} title={props.title} summary={initialData?.meta?.totalCount ? `(${initialData?.meta?.totalCount} Records)` : '(No Records)'} header={
      <>
        <Segmented
          value={viewMode}
          options={[
            {
              value: VIEW_MODES.LIST,
              icon: <BarsOutlined />,
            },
            {
              value: VIEW_MODES.KANABAN,
              icon: <AppstoreOutlined />,
            },
          ]}
          onChange={setViewMode}
        />
        <Space.Compact style={{ width: '100%', maxWidth: '400px' }}>
          <Input.Search placeholder="Search" onChange={(e) => {
            if (e.target.value === '') {
              setSearchParams(sp => {
                sp.delete('filter[search]')
                return sp
              })
              return;
            }
            return debouncedChangeHandler(e.target.value)
          }} defaultValue={searchParamsParsed?.filter?.search ?? ''} allowClear />
          <Button onClick={() => setIsFiltersVisible(v => !v)} icon={<FilterOutlined />}>Filters</Button>
        </Space.Compact>
        <Button type="primary" onClick={() => { navigate(generateRouteUrl('ProjectCreate')) }} icon={<PlusOutlined />}>Add new Project</Button>
      </>
    } subHeader={initialData.meta?.filters && <SelectedFilters filters={initialData.meta.filters} filterNames={FILTER_NAMES} updateFilters={setFilters} />}>
      {viewMode === VIEW_MODES.LIST
        ? <ProjectsListView data={list} isLoading={isLoading} hasMore={hasMore} onChangeSort={setSort} onLoadMore={loadMore} onItemClick={handleRowClick} />
        : <ProjectsKanabanView data={list} kanabanCount={initialData.kanabanCount} isLoading={isLoading} hasMore={hasMore} onLoadMore={loadMore} onItemClick={handleRowClick} />
      }

      <Drawer open={isFiltersVisible} onClose={() => setIsFiltersVisible(false)} >
        <ProjectFilterForm key={JSON.stringify(searchParamsParsed.filter)} data={searchParamsParsed.filter} onFinish={(data) => setFilters(data)} />
      </Drawer>
    </Page>
  )
}

Projects.Loader = async ({ request }) => {
  const url = new URL(request.url)
  const { filter, page, sort, view = (getUserSettings('projectListView') ?? VIEW_MODES.KANABAN) } = searchParamsToObject(url.searchParams)
  setUserSettings('projectListView', view)
  const limit = view === VIEW_MODES.KANABAN ? 50 : 10
  if (filter?.createdAt && filter.createdAt.split(',')) {
    const [start_date = null, end_date = null] = filter.createdAt.split(',')
    if (start_date) {
      filter.start_date = start_date
      filter.end_date = end_date
      delete filter.createdAt
    }
  }
  const { data, meta } = await getAllProjects({ filter, page, limit, ...sort })

  //Loads kanaban count only in list view mode and on first request
  let kanabanCount = null
  if (view === VIEW_MODES.KANABAN && !page) {
    kanabanCount = await getProjectKanabanCount({ filter })
  }

  return {
    data,
    meta,
    kanabanCount
  }
}

Projects.propTypes = {
  title: PropTypes.string
}

export default Projects
