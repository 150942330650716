export const PurchaseBillStatuses = {
    NEW: 1,
    APPROVED: 2,
    ON_HOLD: 3,
    MANUAL_PAYMENT: 4,
    PAID: 5,
}

export const PurchaseBillStatusDetails = {
    [PurchaseBillStatuses.NEW]: "New",
    [PurchaseBillStatuses.APPROVED]: "Approved",
    [PurchaseBillStatuses.ON_HOLD]: "On Hold",
    [PurchaseBillStatuses.MANUAL_PAYMENT]: "Manual Payment",
    [PurchaseBillStatuses.PAID]: "Paid",
}

export const findPurchaseBillStatusById = (status) => PurchaseBillStatusDetails[status]