import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Button, Drawer, Input, Select, message } from 'antd';
import React, { useState } from 'react';
import { Form, useActionData, useLoaderData, useNavigate } from 'react-router-dom';
import { changeUserPassword, getUserDetails, updateUser } from '../../api/user';
import { Box } from '../../components/design/box.js';
import { ProfileUpload } from '../../components/inputs';
import { ValidationError } from '../../library/classes/exceptions/index.js';
import { Permission, RolePermission, UserRoles } from '../../library/constants/dynamic.js';
import { getFieldErrors, getFieldStatus } from '../../library/helpers/forms/index.js';
import Styles from './user-details.module.scss';

const UserDetails = () => {
    const { user } = useLoaderData()
    const navigate = useNavigate()
    const [userType, setUserType] = useState(user.roles)
    const [permission, setPermission] = useState(user.permisions)
    // const [rolesPermssion, setRolesPermission] = useState()
    const { errors = null } = useActionData() || {}

    const handleRolesChange = (roles) => {
        // If the roles array is empty, reset permissions
        if (roles.length === 0) {
            setUserType([])
            setPermission([])
            return
        }

        // Find newly added roles
        // const newRoles = roles.filter(x => !userType.includes(x))
        // const removedRoles = userType.filter(x => !roles.includes(x))
        // console.log({ newRoles, removedRoles, userType })
        // Update userType with new roles

        // setUserType(roles)
        // // Prepare new permissions array
        // let newPermissions = [...permission]

        // //Remove perimssions for removed roles
        // removedRoles?.forEach(r => {
        //     const rolePermissions = RolePermission.find(({ id }) => r === id)?.value || []
        //     newPermissions = newPermissions.filter(a => !rolePermissions?.includes(a))
        // })

        // // For each new role, find and add the corresponding permissions
        // newRoles.forEach(r => {
        //     const rolePermissions = RolePermission.find(({ id }) => r === id)?.value || []
        //     newPermissions = [...newPermissions, ...rolePermissions]
        // })

        // Ensure the permissions are unique using a Set
        // Update the permission state
        let newPermissions = []
        setUserType(roles)
        roles.forEach(r => {
            const rolePermissions = RolePermission.find(({ id }) => r === id)?.value || []
            newPermissions = [...newPermissions, ...rolePermissions]
        })
        setPermission([...new Set(newPermissions)])
    }

    return (
        <Drawer open={true} width="720px" mask={false} title={`User: ${user.name}`} onClose={() => {
            const searchParams = new URLSearchParams(location.search);
            navigate({
                pathname: '..',
                search: `?${searchParams.toString()}`
            }, { replace: true, relative: 'route' });
        }} >
            <ProfileUpload user={user} />

            <Form method="post" className={Styles.formWrapper}>
                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.inputField}>
                        <label>Name</label>
                        <Input type="text" name="name" key={user.name} defaultValue={user.name} required status={getFieldStatus('name', errors)} />
                        {getFieldErrors('name', errors)}
                    </div>
                </div>
                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.inputField}>
                        <label>Email</label>
                        <Input type="email" name="email" key={user.email} defaultValue={user.email} required status={getFieldStatus('email', errors)} />
                        {getFieldErrors('email', errors)}
                    </div>
                </div>
                {/* <div className={Styles.fieldsWrapper}>
                    <div className={Styles.inputField}>
                        <label>Username</label>
                        <Input type="text" name="username" key={user.username} defaultValue={user.username} required status={getFieldStatus('username', errors)} />
                        {getFieldErrors('username', errors)}
                    </div>
                </div> */}
                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.inputField}>
                        <label>Roles</label>
                        <Select
                            key={user.roles}
                            options={UserRoles}
                            mode="multiple"
                            fieldNames={{ value: 'id', label: 'name' }}
                            defaultValue={userType}
                            onChange={handleRolesChange}
                            status={getFieldStatus('type', errors)}
                        />
                        {getFieldErrors('type', errors)}
                        <input type="hidden" name="roles" value={userType} />
                    </div>
                </div>
                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.inputField}>
                        <label>Permission</label>
                        <Select
                            key={user.permission}
                            options={Permission}
                            mode="multiple"
                            value={permission}
                            fieldNames={{ value: 'id', label: 'name' }}
                            defaultValue={permission}
                            onChange={c => setPermission(c)}
                            status={getFieldStatus('type', errors)}
                        />
                        {getFieldErrors('type', errors)}
                        <input type="hidden" name="permission" value={permission} />
                    </div>
                </div>
                {/* <div className={Styles.fieldsWrapper}>
                    <div className={Styles.inputField}>
                        <label>Roles</label>
                        <Select
                            key={user.roles}
                            options={UserRoles}
                            mode="multiple"
                            fieldNames={{ value: 'id', label: 'name' }}
                            defaultValue={user.roles}
                            onChange={c => setUserType(c)}
                            status={getFieldStatus('type', errors)}
                        />
                        {getFieldErrors('type', errors)}
                        <input type="hidden" name="roles" value={userType} />
                    </div>
                </div> */}
                <Button type='primary' name="action" value="updateUser" htmlType='submit'>Update</Button>
            </Form>
            <Box containerStyles={{ marginTop: '1rem' }}>
                <Form method="post" className={Styles.formWrapper}>
                    <div className={Styles.fieldsWrapper}>
                        <div className={Styles.inputField}>
                            <label>New Password</label>
                            <Input.Password
                                placeholder="New Password"
                                name="newpassword"
                                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                status={getFieldStatus('name', errors)}
                                pattern="^(?=.*[0-9])(?=.*[\W_])(?=.*[a-zA-Z])(?=\S+$).{6,}$"
                                title="Password must contain at least one digit, one special character, one letter, and be at least 6 characters."
                                required
                            />
                            {getFieldErrors('name', errors)}
                        </div>
                    </div>
                    <Button type='primary' name="action" value="resetPassword" htmlType='submit'>Reset Password</Button>
                </Form>
            </Box>
        </Drawer >
    )
}

UserDetails.Actions = {
    "updateUser": async ({ params, data }) => {
        const { userId } = params
        const { name, email, roles, permission } = data
        try {
            //Todo: Basic validation
            const { data } = await updateUser(userId, {
                name,
                email,
                // username,
                user_roles: permission == "" ? roles.split(',') : roles.split(',').concat(permission.split(',')),

            })
            message.success("User Update!")
            return data
        }
        catch (error) {
            message.error(error.message)
            if (error instanceof ValidationError) {
                return {
                    errors: error.errors
                }
            }
        }
        return false
    },
    "resetPassword": async ({ params, data }) => {
        const { userId } = params
        const { newpassword } = data

        try {
            const msg = await changeUserPassword(userId, newpassword)
            message.success(msg)
            return true
        }
        catch (error) {
            if (error instanceof ValidationError) {
                return {
                    errors: error.errors
                }
            }
        }
        return false
    }
}

UserDetails.Loader = async ({ params }) => {
    const { userId } = params
    const user = await getUserDetails(userId)
    return { user }
}

export default UserDetails







