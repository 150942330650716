import { Radio } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const VendorRoles = Object.freeze({
	primary: "Primary",
	enquiry: 'Enquiry',
	order: 'Order',
})

const VendorContactRoleSelect = ({ contactId, role, onChange, ...otherProps }) => {
	return (
		<Radio.Group key={role} options={Object.entries(VendorRoles).map(([value, label]) => ({
			value,
			label
		}))} optionType="button" buttonStyle="solid" defaultValue={role} {...otherProps} onChange={e => onChange(e.target.value, contactId)} />
	);
};
VendorContactRoleSelect.propTypes = {
	contactId: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.oneOf(['new']),
	]).isRequired,
	role: PropTypes.oneOf(Object.keys(VendorRoles)).isRequired,
	onChange: PropTypes.func,
};

export { VendorContactRoleSelect, VendorRoles };

