import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Upload, message } from 'antd';
import { PropTypes } from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useRevalidator } from 'react-router-dom';
import { uploadFileFromObject } from '../../api/common';
import { removeProfilePhoto, updateUser } from '../../api/user';
import { Server } from '../../library/constants';

const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
};
const ProfileUpload = ({ user }) => {
    const [loading, setLoading] = useState(false);
    const revalidator = useRevalidator()
    const [image, setImage] = useState(user.profile ? {
        id: '1',
        name: user.profile,
        status: 'done',
        url: `${Server.BaseUrl}/api/v1/user/profile/${user.profile}`,
    } : null)

    const uploadButton = useMemo(() => (
        !image?.url && (
            <div>
                {loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div
                    style={{
                        marginTop: 8,
                    }}
                >
                    Upload
                </div>
            </div>
        )
    ), [loading, image])

    const handleFileUpload = async (file) => {
        try {
            setLoading(true)
            setImage({
                id: '1',
                name: '',
                status: 'uploading',
                url: null
            })
            const id = await uploadFileFromObject(file)
            const payload = {
                name: user.name,
                email: user.email,
                type: user.type,
                username: user.username,
                profile: id
            }
            const { message: msg, data } = await updateUser(user.id,
                payload
            )
            setImage({
                id: '1',
                name: 'data.profile',
                status: 'done',
                url: `${Server.BaseUrl}/api/v1/user/profile/${data.profile}`,
            })
            setLoading(false)
            message.success(msg)
            revalidator.revalidate()

        }
        catch (error) {
            setLoading(false)
            throw new Response('Unable to upload files', { status: 500 })
        }
    }
    const handleFileRemove = async () => {
        try {
            setLoading(true)
            setImage(img => ({
                ...img,
                name: '',
                status: 'uploading',
            }))
            const msg = await removeProfilePhoto(user.id)
            message.success(msg)
            setImage(null)
            setLoading(false)
            revalidator.revalidate()

        }
        catch (error) {
            setLoading(false)
            throw new Response('Unable to remove files', { status: 500 })
        }
    }

    const handleChange = async (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            console.log(info);
        }
    }

    return (
        <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            fileList={image ? [image] : []}
            customRequest={(file) => { handleFileUpload(file.file) }}
            beforeUpload={beforeUpload}
            onChange={handleChange}
            multiple={false}
            onRemove={handleFileRemove}
        >
            {/* {user.profile != null ? (
                    <img
                        src={imageUrl}
                        alt="Profile Photo"
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: "cover"
                        }}
                    />
                ) : (
                    uploadButton
                )} */}
            {uploadButton}
        </Upload>
    );
};
ProfileUpload.propTypes = {
    user: PropTypes.object
}

export default ProfileUpload;