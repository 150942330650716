import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Button, Drawer, Input, message, Select } from 'antd';
import React, { useState } from 'react';
import { Form, useActionData, useLoaderData, useNavigate } from 'react-router-dom';
import { changeUserPassword, getUserDetails, updateUser } from '../../api/user';
import { ValidationError } from '../../library/classes/exceptions/index.js';
import { VendorUserRoles } from '../../library/constants/VendorUserRoles.js';
import { getFieldErrors, getFieldStatus } from '../../library/helpers/forms/index.js';
import Styles from './user-details.module.scss';

const VendorUserDetails = () => {
    const { user } = useLoaderData()
    const navigate = useNavigate()
    const { errors = null } = useActionData() || {}
    const [userType, setUserType] = useState(user.roles)

    return (
        <Drawer open={true} mask={false} title={`User: ${user.name}`} onClose={() => navigate('..', { replace: true, relative: 'route' })} >
            <Form method="post" className={Styles.formWrapper}>
                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.inputField}>
                        <label>Name</label>
                        <Input type="text" name="name" key={user.name} defaultValue={user.name} required status={getFieldStatus('name', errors)} />
                        {getFieldErrors('name', errors)}
                    </div>
                </div>
                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.inputField}>
                        <label>Email</label>
                        <Input type="email" name="email" key={user.email} defaultValue={user.email} required status={getFieldStatus('email', errors)} />
                        {getFieldErrors('email', errors)}
                    </div>
                </div>
                {/* <div className={Styles.fieldsWrapper}>
                    <div className={Styles.inputField}>
                        <label>Username</label>
                        <Input type="text" name="username" key={user.username} defaultValue={user.username} required status={getFieldStatus('username', errors)} />
                        {getFieldErrors('username', errors)}
                    </div>
                </div> */}
                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.inputField}>
                        <label>Roles</label>
                        <Select
                            key={user.roles}
                            options={VendorUserRoles}
                            mode="multiple"
                            fieldNames={{ value: 'id', label: 'name' }}
                            defaultValue={user.roles}
                            onChange={(c) => {
                                setUserType(c)
                            }}
                            status={getFieldStatus('user_roles', errors)}
                        />
                        {getFieldErrors('user_roles', errors)}
                        <input type="hidden" name="roles" value={userType} />
                    </div>
                </div>
                <Button type='primary' name="action" value="updateUser" htmlType='submit'>Update</Button>
            </Form>
            <Form method="post" className={Styles.formWrapper}>
                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.inputField}>
                        <label>New Password</label>
                        <Input.Password
                            placeholder="New Password"
                            name="newpassword"
                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            status={getFieldStatus('name', errors)}
                            pattern="^(?=.*[0-9])(?=.*[\W_])(?=.*[a-zA-Z])(?=\S+$).{6,}$"
                            title="Password must contain at least one digit, one special character, one letter, and be at least 6 characters."
                            required
                        />
                        {getFieldErrors('name', errors)}
                    </div>
                </div>
                <Button type='primary' name="action" value="resetPassword" htmlType='submit'>Reset Password</Button>
            </Form>
        </Drawer >
    )
}

VendorUserDetails.Actions = {
    updateProfilePhoto: async ({ params, data }) => {
        const { uId } = params
        const payload = data;
        try {
            //Todo: Basic validation
            const { message: msg, data } = await updateUser(uId, payload)
            message.success(msg)
            return data
        }
        catch (error) {
            message.error(error.message)
            if (error instanceof ValidationError) {
                return {
                    errors: error.errors
                }
            }
        }
        return false
    },
    updateUser: async ({ params, data }) => {
        const { uId } = params
        const { name, email, roles } = data
        try {
            //Todo: Basic validation
            const { data } = await updateUser(uId, {
                name,
                email,
                // username,
                user_roles: roles.split(',')
            })
            message.success("User Update!")
            return data
        }
        catch (error) {
            message.error(error.message)
            if (error instanceof ValidationError) {
                return {
                    errors: error.errors
                }
            }
        }
        return false
    },
    resetPassword: async ({ params, data }) => {
        const { uId } = params
        const { newpassword } = data

        try {
            const msg = await changeUserPassword(uId, newpassword)
            message.success(msg)
            return true
        }
        catch (error) {
            if (error instanceof ValidationError) {
                return {
                    errors: error.errors
                }
            }
        }
        return false
    }
}

VendorUserDetails.Loader = async ({ params }) => {
    const { uId } = params
    const user = await getUserDetails(uId)
    return { user }
}

export default VendorUserDetails







