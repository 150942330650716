import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, message } from 'antd';
import React from 'react';
//import { emailLogin } from '../../api/auth';
import PropTypes from 'prop-types';
import { Link, redirect, useFetcher } from 'react-router-dom';
import { emailLogin } from '../../api/auth/login.js';
import { getNotifications } from '../../api/auth/notifications.js';
import { setUserSessionData } from '../../library/constants/auth.js';
import { generateRouteUrl } from '../../library/constants/routes.js';
import { UserPermissions } from '../../library/constants/userPermissions.js';
import styles from './login.module.scss';

export const getDefaultPageByRole = (roles, permisions) => {
  //Todo: Add logic for more roles
  if (permisions?.includes(UserPermissions.DASHBOARD)) {
    return generateRouteUrl('Dashboard')
  }
  if (roles.includes('order_manager')) {
    return generateRouteUrl('OrderManagerList')
  }
  if (roles.includes('administration') || roles.includes('administration_manager')) {
    return generateRouteUrl('FinanceProjects')
  }
  if (roles.includes('studio')) {
    return generateRouteUrl('StudioList')
  }
  return generateRouteUrl('Dashboard')
}

const validationRules = {
  username: [
    {
      required: true,
      message: 'Please enter your Username!',
    },
  ],
  password: [
    {
      required: true,
      message: 'Please enter your Password!',
    },
  ],
}

const Login = () => {
  const fetcher = useFetcher()

  const handleSubmit = ({ username, password }) => {
    fetcher.submit({
      username,
      password
    }, {
      method: 'post',
      encType: 'application/json'
    })
  };

  return (
    <Home>
      <Form onFinish={handleSubmit} size='large'>
        <Form.Item name="username">
          <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" rules={validationRules.username} style={{ borderRadius: 0 }} />
        </Form.Item>
        <Form.Item name="password">
          <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="Password" rules={validationRules.password} style={{ borderRadius: 0 }} />
        </Form.Item>
        <Form.Item>
          <div className={styles.loginButtonWrapper}>
            <Link to={'/reset-password'} className="login-form-forgot">Forgot password</Link>
            <Button type="primary" htmlType="submit" className="login-form-button" loading={fetcher.state !== 'idle'}>Log in</Button>
          </div>
        </Form.Item>
      </Form>
    </Home>
  )
}
Login.Action = async ({ request }) => {
  const { username, password } = await request.json()
  if (!username || !password) {
    message.error('Email and password is required')
    return false
  }
  try {
    const { data } = await emailLogin({}, { email: username, password })

    //Get notifications
    const { notifications, unreadCount } = await getNotifications()
    localStorage.setItem('USER', JSON.stringify(data));
    setUserSessionData('NOTIFICATIONS', JSON.stringify({ notifications, unreadCount }))
    localStorage.setItem('ACCESS_TOKEN', data?.a_tkn?.tkn);
    localStorage.setItem('REFRESH_TOKEN', data?.r_tkn?.tkn);

    let params = new URL(request.url).searchParams;
    let from = params.get("from");
    let redirectTo = from ?? getDefaultPageByRole(data.roles ?? [])
    message.destroy()
    return redirect(redirectTo);
  } catch (e) {
    console.log('LOGIN_ERROR: ', e);
    message.error(e.message)
  }
  return false
}

const Home = ({ children }) => {
  return (
    <div className="page login">
      <div className={styles.pageWrapper}>
        <div className={styles.imageWrapper}></div>
        <div className={styles.loginWrapper}>
          <img src='/images/logo.svg' className={styles.logo} />
          {children}
        </div>
      </div>
    </div>
  )
}
Home.propTypes = {
  children: PropTypes.node,
}

export { Home, Login };
